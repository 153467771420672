import React, {useState, useEffect} from 'react'
import {MobXProviderContext} from 'mobx-react'
import municipalities from '../municipalities.json'
import {useTranslation} from 'react-i18next'

interface IHandler {
  (event: {target: any}): void
  (event: {target: any}): void
  (this: Document, ev: MouseEvent): any
  (this: Document, ev: TouchEvent): any
}

/**
 * Click/Touch handler
 *
 * Add/Remove event listeners based on ref target. Used for dropdown.
 */
export const useClickOutside = (ref: {current: any}, onClickOutside: (e: any) => void, isActive: any) => {
  const [isListening, setListening] = useState(false)
  const handler = (event: {target: any}) => {
    const {current: el} = ref
    if (el && event.target && event.target instanceof HTMLElement && !el.contains(event.target)) {
      onClickOutside(event)
    }
  }
  const addHandlers = (handler: IHandler) => {
    document.addEventListener('mousedown', handler)
    document.addEventListener('touchstart', handler)
  }
  const removeHandlers = (handler: IHandler) => {
    document.removeEventListener('mousedown', handler)
    document.removeEventListener('touchstart', handler)
  }
  useEffect(() => {
    if (!isListening && isActive) {
      addHandlers(handler)
      setListening(true)
    } else if (isListening && !isActive) {
      removeHandlers(handler)
      setListening(false)
    }
    return () => {
      removeHandlers(handler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, !onClickOutside, isActive])
}

export const useStores = () => React.useContext(MobXProviderContext)

const getWindowWidth = () => {
  const {innerWidth: width} = window
  return {
    width
  }
}

export const useWindowWidth = () => {
  const [windowDimensions, setWindowDimensions] = useState(getWindowWidth())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowWidth())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const useAutoSaveTimer = (save: () => Promise<unknown>) => {
  const SAVE_SECOND_INTERVAL = 5
  const [seconds, setSeconds] = useState(1)
  const [resetTimerCount, setResetTimerCount] = useState(0)
  const [editingStopped, setEditingStopped] = useState<boolean | undefined>(undefined)
  useEffect(() => {
    const autoSave = async () => save()
    let timerId = 0

    if (editingStopped && resetTimerCount > 0) {
      clearInterval(timerId)
      setSeconds(1)
      setResetTimerCount(0)
      timerId = window.setInterval(() => {
        setSeconds(seconds + 1)
      }, 1000)
    } else if (editingStopped && seconds < SAVE_SECOND_INTERVAL) {
      timerId = window.setInterval(() => {
        setSeconds(seconds + 1)
      }, 1000)
    }
    if (seconds === SAVE_SECOND_INTERVAL) {
      autoSave()
      setSeconds(1)
      setEditingStopped(undefined)
    }

    return () => clearInterval(timerId)
  }, [editingStopped, seconds, resetTimerCount, save])
  return {seconds, resetTimerCount, setResetTimerCount, editingStopped, setEditingStopped}
}

export const useKeyPress = (key: string, action: () => void) => {
  useEffect(() => {
    const onKeyChange = (e: KeyboardEvent) => {
      if (e.key === key) {
        return action()
      }
    }
    window.addEventListener('keydown', onKeyChange)
    return () => window.removeEventListener('keydown', onKeyChange)
  }, [key, action])
}

export const useMunicipalities = () => {
  const {i18n} = useTranslation()
  const municipalityOptions = municipalities.map((c: any) => ({key: c.municipality_id, title: c[`name_${i18n.language}`]}))
  return {municipalityOptions}
}
