import * as React from 'react'
import { BrowserRouter, Route, Routes, Navigate, useMatch } from 'react-router-dom'

import styled, { createGlobalStyle, ThemeProvider } from 'styled-components'
import { Provider } from 'mobx-react'
import { WelcomePage } from './pages/welcome.page'
import { theme } from './theme'
import { Stores } from './stores'
import { PrivateRoute } from './components/private.route'
import { Notifications } from './components/Notifications'
import { NoAuthPage } from './pages/no.auth.page'
import { QuestionsPage } from './pages/questions.page'
import { SummaryPage } from './pages/summary.page'
import { initi18n } from './i18n/i18n'
import { FAQPage } from './pages/ukk.page'
import { CandidateImagePage } from './pages/candidateimage.page'
import { Dialogs } from './components/Dialogs'
import { AdditionalQuestionsPage } from './pages/additionalquestions.page'
import { ReminderPage } from './pages/reminder.page'
import { RegisterPage } from './pages/register.page'
import { CreateNewPage } from './pages/createNew.page'
import { InfoPage } from './pages/info.page'
import { SMSLoginPage } from './pages/SMSLogin.page'

import { BackgroundQuestionsPage } from './pages/BackgroundQuestions.page'
import { VideoIntroPage } from './pages/VideoIntro.page'
import { useLayoutEffect, useState } from 'react'
import { electionTheme, PageLoader } from '@webscale-oy/vaalikone-common-ui-base'

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
  }
   body,
   input,
   textarea {
      font-family: ${theme.font.text};
   }
   h1,h2,h3,h4 {
    font-family: ${theme.font.header};
   }
   * {
    box-sizing: border-box;
   }
   ul, ol {
    list-style: none;
   }
   a, a:visited {
    color: inherit;
    text-decoration: none;
   }
`
const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
`

export const VaalikoneCandidateApp = ({ stores }: { stores: Stores }) => {
  return (
    <BrowserRouter basename="/">
      <InnerVaalikoneCandidateApp stores={stores} />
    </BrowserRouter>
  )
}

const InnerVaalikoneCandidateApp: React.FC<{ stores: Stores }> = ({ stores }) => {
  const [loading, setLoading] = useState(true)
  const { candidateId } = useMatch('/:candidateId/*')?.params ?? {}

  useLayoutEffect(() => {
    const tryLogin = async () => {
      await initi18n()
      const params = window.location.search.split('vaalikone_token=')
      if (params.length === 2) {
        const token = params[1]
        const { candidateStore } = stores
        try {
          await candidateStore!.login(token)
          window.location.replace(`/${candidateStore.candidateId}/tervetuloa`)
        } catch (error) {
          candidateStore!.notificationStore.createNotification('Tunnistautuminen epäonnistui.', 'error')
        }
      }
      setLoading(false)
    }
    tryLogin()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ThemeProvider theme={electionTheme}>
      <Provider {...stores}>
        {loading ? (
          <LoadContainer>
            <PageLoader />
          </LoadContainer>
        ) : (
          <Routes>
            <Route
              path="/:candidateId/tervetuloa"
              element={
                <PrivateRoute>
                  <WelcomePage />
                </PrivateRoute>
              }
            />
            <Route
              path="/:candidateId/info"
              element={
                <PrivateRoute>
                  <InfoPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/:candidateId/taustatiedot/*"
              element={
                <Routes>
                  <Route
                    path="/taustakysymykset"
                    element={
                      <PrivateRoute>
                        <BackgroundQuestionsPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/kuva"
                    element={
                      <PrivateRoute>
                        <CandidateImagePage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <Navigate to={`/${candidateId}/taustatiedot/taustakysymykset`} />
                    }
                  />
                </Routes>
              }
            />
            <Route
              path="/:candidateId/kuntavaalit/*"
              element={
                <Routes>
                  <Route
                    path="/video"
                    element={
                      <PrivateRoute>
                        <VideoIntroPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/kysymykset"
                    element={
                      <PrivateRoute>
                        <QuestionsPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/mediakumppanit"
                    element={
                      <PrivateRoute>
                        <AdditionalQuestionsPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <Navigate to={`/${candidateId}/kuntavaalit/video`} />
                    }
                  />
                </Routes>
              }
            />
            <Route
              path="/:candidateId/aluevaalit/*"
              element={
                <Routes>
                  <Route
                    path="/video"
                    element={
                      <PrivateRoute>
                        <VideoIntroPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/kysymykset"
                    element={
                      <PrivateRoute>
                        <QuestionsPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/mediakumppanit"
                    element={
                      <PrivateRoute>
                        <AdditionalQuestionsPage />
                      </PrivateRoute>
                    }
                  />
                  <Route
                    path="/"
                    element={
                      <Navigate to={`/${candidateId}/aluevaalit/video`} />
                    }
                  />
                </Routes>
              }
            />
            <Route
              path="/:candidateId/yhteenveto"
              element={
                <PrivateRoute>
                  <SummaryPage />
                </PrivateRoute>
              }
            />

            <Route
              path="/:candidateId/ukk/lang/:lang"
              element={
                <PrivateRoute>
                  <FAQPage />
                </PrivateRoute>
              }
            />
            <Route
              path="/:candidateId/muistutus"
              element={
                <PrivateRoute>
                  <ReminderPage />
                </PrivateRoute>
              }
            />
            <Route path="/ukk/lang/:lang" element={<FAQPage />} />
            <Route path="/tunnistaudu" element={<RegisterPage />} />
            <Route path="/kirjaudu" element={<SMSLoginPage />} />
            <Route path="/luo-profiili" element={<CreateNewPage />} />
            {!stores.candidateStore.isAuthenticated && <Route path="/" element={<NoAuthPage />} />}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        )}
      </Provider>
      <Notifications store={stores.notificationStore!} />
      <Dialogs dialogStore={stores.dialogStore!} />
      <GlobalStyle />
    </ThemeProvider>
  )
}
