import { action, makeAutoObservable } from 'mobx'
import { authenticatedHeaders, get, post } from '../api/methods'
import { IConstituency } from '../types/constituency'
import { IParty } from '../types/party'
import { ElectionType } from '../types/election'

type CandidateInfo = {
  first_name: string
  last_name: string
  token?: string
  login_token?: string
}

type NewCandidate = {
  first_name: string
  last_name: string
  email: string
  constituency_id: number | undefined
  extra_constituency_id?: number
  party_id: number | undefined
  extra_party_id?: number
  election_type?: ElectionType
}

export class RegistrationStore {
  public jwtToken = ''
  public newCandidate: NewCandidate = {
    first_name: '',
    last_name: '',
    email: '',
    party_id: undefined,
    constituency_id: undefined,
    extra_constituency_id: undefined,
    extra_party_id: undefined
  }
  public constituencies: IConstituency[] = []
  public parties: IParty[] = []
  public loading = true
  public error = false

  constructor() {
    makeAutoObservable(this)
  }

  @action
  public authenticateCandidate = async (code: string) => {
    const redirectUri = `${window.location.origin}/luo-profiili`
    const candidate = await post<CandidateInfo>(`authenticate`, { token: code, redirectUri })
    if (candidate) {
      if (candidate.login_token) {
        window.location.replace(String(candidate.login_token))
        return false
      } else {
        this.jwtToken = candidate?.token || ''
        this.newCandidate = {
          ...this.newCandidate,
          first_name: candidate.first_name,
          last_name: candidate.last_name
        }
        return true
      }
    } else {
      throw Error('Code not valid')
    }
  }

  @action
  public fetchElectionData = async () => {
    const data = await get<{ constituencies: IConstituency[]; parties: IParty[] }>('election-data', authenticatedHeaders(this.jwtToken))
    this.constituencies = data.constituencies
    this.parties = data.parties
    this.loading = false
  }

  @action
  public createNewCandidate = async (constituency_id?: number, extra_constituency_id?: number) => {
    try {
      const redirectUrl = await post<string>('create-new', {
        ...this.newCandidate,
        constituency_id,
        extra_constituency_id
      }, authenticatedHeaders(this.jwtToken))
      window.location.replace(String(redirectUrl))
    } catch (err) {
      this.error = true
    }
  }

  @action
  public setError = (value: boolean) => {
    this.error = value
  }

  @action
  public setNewCandidate = (value: NewCandidate) => {
    this.newCandidate = value
  }
}
