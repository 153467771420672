import React, {FC, ReactNode, useEffect, useMemo, useRef, useState} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {RiUser3Fill, RiCameraFill, RiVideoChatLine} from 'react-icons/ri'
import {MdForum} from 'react-icons/md'
import {BsChatLeftTextFill} from 'react-icons/bs'
import {
  Dropdown,
  WarningDialogModal,
  breakpoints,
  colors,
  spacing,
  subheaderLight,
  textBold,
  textH1
} from '@webscale-oy/vaalikone-common-ui-base'
import {NavSectionWrapper, ProgressNavBarV2} from '@webscale-oy/vaalikone-common-ui-candidate'
import {observer} from 'mobx-react'
import {useStores} from '../utils/hooks'
import {Stores} from '../stores'

type Props = {
  header: string
  description?: string
  continueCondition?: boolean
  navigationPath?: string
  navigationClickCount?: number
  children?: ReactNode
}

type SectionOption = {
  title: string
  path: string
  done?: boolean
  icon?: JSX.Element
}

export const AnswersWrapper: FC<Props> = observer(
  ({children, header, description, continueCondition, navigationPath, navigationClickCount}) => {
    const {t} = useTranslation()
    const {pathname} = useLocation()
    const navigate = useNavigate()
    const topRef: React.RefObject<HTMLDivElement> | null = useRef(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined)
    const {candidateId} = useParams<{candidateId: string}>()
    const {mediapartnerStore} = useStores() as Stores
    const headerRef = useRef<HTMLHeadingElement>(null)

    useEffect(() => {
      if (headerRef.current) {
        headerRef.current.focus()
      }
    }, [pathname])

    const sections = useMemo(() => {
      const sections: SectionOption[] = []
      if (pathname.includes('taustatiedot')) {
        sections.push({
          title: t('sectionNav.bgQuestions'),
          path: `/${candidateId}/taustatiedot/taustakysymykset`,
          done: false,
          icon: <RiUser3Fill />
        })
        sections.push({
          title: t('sectionNav.photo'),
          path: `/${candidateId}/taustatiedot/kuva`,
          done: false,
          icon: <RiCameraFill />
        })
      }
      if (pathname.includes('kuntavaalit')) {
        sections.push({
          title: t('sectionNav.video'),
          path: `/${candidateId}/kuntavaalit/video`,
          done: false,
          icon: <RiVideoChatLine />
        })
        sections.push({
          title: t('sectionNav.questions'),
          path: `/${candidateId}/kuntavaalit/kysymykset`,
          done: false,
          icon: <BsChatLeftTextFill />
        })
        if (mediapartnerStore.hasMunicipalityQuestions) {
          sections.push({
            title: t('sectionNav.mediapartnerQuestions'),
            path: `/${candidateId}/kuntavaalit/mediakumppanit`,
            done: false,
            icon: <MdForum />
          })
        }
      }

      if (pathname.includes('aluevaalit')) {
        sections.push({
          title: t('sectionNav.video'),
          path: `/${candidateId}/aluevaalit/video`,
          done: false,
          icon: <RiVideoChatLine />
        })
        sections.push({
          title: t('sectionNav.questions'),
          path: `/${candidateId}/aluevaalit/kysymykset`,
          done: false,
          icon: <BsChatLeftTextFill />
        })
        if (mediapartnerStore.hasCountyQuestions) {
          sections.push({
            title: t('sectionNav.mediapartnerQuestions'),
            path: `/${candidateId}/aluevaalit/mediakumppanit`,
            done: false,
            icon: <MdForum />
          })
        }
      }
      return sections
    }, [candidateId, mediapartnerStore.hasCountyQuestions, mediapartnerStore.hasMunicipalityQuestions, pathname, t])

    useEffect(() => {
      if (topRef && topRef.current) {
        window.scrollTo(0, 0)
      }
    }, [])

    useEffect(() => {
      if (navigationPath) {
        onNavigateClick(navigationPath)
      } else if (navigateTo) {
        onNavigateClick(navigateTo)
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigationPath, navigateTo, navigationClickCount])

    const onConfirm = () => navigateTo && navigate(navigateTo)

    const onNavigateClick = (to: string) => {
      if (to === pathname) {
        return undefined
      }
      if (continueCondition === false) {
        setModalOpen(true)
        setNavigateTo(to)
      } else {
        navigate(to)
      }
    }

    const modalBodyTranslation = () => {
      if (pathname.includes('kuva')) {
        return t('confirmModal.bodyProfile')
      }
      if (pathname.includes('taustakysymykset')) {
        return t('confirmModal.bodyBgQuestions')
      }
      if (pathname.includes('/kysymykset')) {
        return t('confirmModal.bodyQuestions')
      }
      if (pathname.includes('/video')) {
        return t('confirmModal.bodyVideo')
      }
      if (pathname.includes('/mediakumppanit')) {
        return t('confirmModal.bodyPartnerQuestions')
      }
      return ''
    }

    const modalHeaderTranslation = () => {
      if (pathname.includes('/kuva')) {
        return t('confirmModal.headerProfile')
      }
      if (pathname.includes('/taustakysymykset')) {
        return t('confirmModal.headerBgQuestions')
      }
      if (pathname.includes('/kysymykset')) {
        return t('confirmModal.headerQuestions')
      }
      if (pathname.includes('/video')) {
        return t('confirmModal.headerVideo')
      }
      if (pathname.includes('/mediakumppanit')) {
        return t('confirmModal.headerPartnerQuestions')
      }
      return ''
    }

    const modalConfirmTranslation = () => {
      if (pathname.includes('/kuva')) {
        return t('confirmModal.confirmProfile')
      }
      if (pathname.includes('/taustakysymykset')) {
        return t('confirmModal.confirmBgQuestions')
      }
      if (pathname.includes('/kysymykset')) {
        return t('confirmModal.confirmQuestions')
      }
      if (pathname.includes('/video')) {
        return t('confirmModal.confirmVideo')
      }
      if (pathname.includes('/mediakumppanit')) {
        return t('confirmModal.confirmPartnerQuestions')
      }
      return ''
    }

    const onModalCancel = () => {
      setModalOpen(false)
    }

    return (
      <ContentContainer ref={topRef}>
        <ProgressNavi
          sections={sections}
          currentPath={pathname}
          onSectionClick={to => onNavigateClick(to)}
          options={{hideNumbering: true}}
          menuButtonBgColorOpen={colors.blue_50}
          menuButtonTextColor={colors.blue_900}
          rightDivider={{
            color: colors.blue_600
          }}
          dropdownButtonBorder={`1px solid ${colors.blue_600}`}
        />
        {!!description && <InfoText>{t('pages.profilePage.mainDescription')}</InfoText>}
        <Header ref={headerRef} tabIndex={-1}>{header}</Header>
        {children}
        {modalOpen && (
          <DialogCustomWidth
            headerText={modalHeaderTranslation()}
            cancelText={t('confirmModal.cancel')}
            confirmText={modalConfirmTranslation()}
            bodyText={modalBodyTranslation()}
            onConfirm={onConfirm}
            onClose={onModalCancel}
          />
        )}
      </ContentContainer>
    )
  }
)

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 760px;
  margin: 0 0 ${spacing.space_56}px 0;
  padding: ${spacing.space_16}px;

  ${ProgressNavBarV2} {
    width: calc(100vw - 48px);
    > div {
      > div {
        width: 100%;
      }
    }
    ${Dropdown} {
      button {
        ${textBold};
        > div {
          svg {
            width: 20px;
            height: 20px;
            margin-left: 12px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    padding: 0;
    width: 100%;
  }
`

const Header = styled.h1`
  ${textH1};
  overflow-wrap: break-word;
  width: 100%;
  margin: ${spacing.space_48}px 0;
`

const InfoText = styled.p`
  ${subheaderLight}
  margin-bottom: ${spacing.space_32}px;
`

const DialogCustomWidth = styled(WarningDialogModal)`
  > div {
    width: 500px;
  }
  @media only screen and (max-width: ${breakpoints.mobile}) {
    > div {
      width: 300px;
    }
  }
`

const ProgressNavi = styled(ProgressNavBarV2)`
  display: none;
  @media screen and (min-width: ${breakpoints.mobile}) {
    display: flex;
  }
  ${NavSectionWrapper} {
    width: 100%;
    border: 0;
    border-radius: 8px;
    > span {
      color: rgba(0, 0, 0, 0.6);
    }
    &.section-wrapper-active {
      background-color: #e2e9fd;
      > span {
        color: #3869d3;
      }
    }
  }
  width: calc(100vw - 48px);
  ${Dropdown} {
    display: none;
  }
`
