import axios, {AxiosError, AxiosRequestConfig} from 'axios'

import {stores} from '../index'

import { awsRum } from '../utils/awsRum.util'
import { commonI18N } from '@webscale-oy/vaalikone-common-ui-base'

type ApiError = {
  message?: string
  code?: string
}

export const API_URL = import.meta.env.VITE_API_URL

export const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}
export const authenticatedHeaders = (jwt?: string, contentType?: string) => {
  const originals = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: `Bearer ${jwt ?? stores.candidateStore.jwt}`
  }

return !!contentType ? {...originals, 'Content-Type': contentType} : originals
}

const createRequest = <T>(path: string, options: AxiosRequestConfig): Promise<T> =>
  axios(`${API_URL}/${path}`, options)
    .then(res => res.data)
    .catch((err: AxiosError<ApiError>) => {
      if (err.response) {
        awsRum?.recordEvent('axiosError', {...err.toJSON()})
        awsRum?.recordError(err)
        switch (err.response.status) {
          case 401:
            if (err.response.data && err.response.data.message === 'Candidate API has been closed') {
              stores.notificationStore!.createNotification(commonI18N.t('errors.candidateServiceClosed')!, 'error')
            } else if (options.headers && options.headers.Authorization) {
              stores.notificationStore!.createNotification(commonI18N.t('errors.sessionExpired')!, 'error')
            } else if (err.response.data && err.response.data.message === 'profile_locked') {
              stores.notificationStore!.createNotification(commonI18N.t('errors.profilelocked')!, 'error')
              return
            }
            break
          case 403:
            stores.notificationStore!.createNotification(commonI18N.t('errors.unauthorizedAction')!, 'error')
            break
          case 502:
          case 503:
          case 504:
            stores.notificationStore!.createNotification(commonI18N.t('errors.serviceUnavailable')!, 'error')
            break
          case 500:
            stores.notificationStore!.createNotification(commonI18N.t('errors.unexpectedError')!, 'error')
            break
        }
      }
      console.error(err)
      throw err
    })

export const get = <T>(path: string, headers = defaultHeaders): Promise<T> => createRequest(path, {headers, method: 'GET'})

export const post = <T>(path: string, data: unknown, headers = defaultHeaders): Promise<T | undefined> =>
  createRequest(path, {headers, data, method: 'POST'})

export const put = <T>(path: string, data: unknown, headers = defaultHeaders): Promise<T | undefined> =>
  createRequest(path, {headers, data, method: 'PUT'})

export const del = <T>(path: string, headers = defaultHeaders): Promise<T | undefined> => createRequest(path, {headers, method: 'DELETE'})
