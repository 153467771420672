import React, { FC, useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Stores } from '../stores'
import { IMediapartnerQuestionAnswer } from '../types/answer'
import { IMediapartnerQuestion } from '../types/question'
import { useStores } from '../utils/hooks'
import { AnswersWrapper } from '../templates/AnswersWrapper.template'
import { getAnswerFieldByType, getInitialValueByType } from '../utils/answer.util'
import { I18nHelper } from '../i18n/i18nHelper'
import {
  CategoryHeader,
  ComponentWrapper,
  InfoP,
  QuestionContainer,
  QuestionCountSection,
  QuestionHeader
} from '../elements/questions/QuestionCommon'
import { deepClone } from '../utils/helper.util'
import { ResponsiveForm } from '../elements/ResponsiveForm'
import { theme } from '../theme'
import {
  FloatingProgress,
  LinkButton,
  OneToFiveInput,
  PaperWithStatus,
  YesNoQuestion,
  determineErrorStatus,
  useForm
} from '@webscale-oy/vaalikone-common-ui-base'
import { NextBackNav } from '@webscale-oy/vaalikone-common-ui-candidate'
import { YesNowWrapper } from './questions.page'
import { FormMultiLangTextarea } from '../components/FormElements/TextArea/MultiLangTextarea'
import { ElectionType } from '../types/election'

interface AnswerForm {
  [key: number]: any
}

const REASONING_LIMIT = 1000

export const AdditionalQuestionsPage: FC = observer(() => {
  const [navClickCount, setNavClickCount] = useState<number>(0)
  const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined)
  const { dialogStore, mediapartnerStore, notificationStore, candidateStore } = useStores() as Stores
  const { candidateId } = useParams<{ candidateId: string }>()
  const { t, i18n } = useTranslation()
  const { item, setNestedValue, setItem } = useForm<AnswerForm>()
  const [errors, setErrors] = useState<AnswerForm>({})
  const [updatebleAnswerId, setUpdatebleAnswerId] = useState<number | undefined>(undefined)
  const answers = mediapartnerStore.mediapartnerQuestionAnswers
  const readOnly = candidateStore.candidate?.status === 'COMPLETED'
  const candidate = candidateStore.candidate
  const { pathname } = useLocation()

  useEffect(() => {
    const state = {} as any
    const errorsObj = {} as any

      ; (answers || []).forEach((answer: IMediapartnerQuestionAnswer) => {
        const { mediapartner_question_id, candidate_id, ...rest } = answer
        state[answer.mediapartner_question_id] = deepClone(rest)
        errorsObj[answer.mediapartner_question_id] = false
      })
    setItem(state)
    setErrors(errorsObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediapartnerStore.mediapartnerQuestionAnswers])

  const confirmPassChoice = async (continueLaterDialog?: boolean) =>
    continueLaterDialog
      ? dialogStore!.openDialog(
        t('additionalQuestionPage.saveAndContinueLaterTitle'),
        <span>{t('additionalQuestionPage.saveAndContinueLaterBody')}</span>,
        'warning'
      )
      : dialogStore!.openDialog(
        t('additionalQuestionPage.passQuestionModalTitle'),
        <span>{t('additionalQuestionPage.passQuestionBody')}</span>,
        'warning'
      )

  const updateAnswer = async (question: IMediapartnerQuestion, data: Partial<IMediapartnerQuestionAnswer>) => {
    const savedAnswer = answers.find(a => a.mediapartner_question_id === question.id)?.[getAnswerFieldByType(question.type)]
    const answerChanged = savedAnswer !== data[getAnswerFieldByType(question.type)]
    const reasoningChanged =
      data[I18nHelper.getExplanationLangKey('fi')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('fi')] ||
      data[I18nHelper.getExplanationLangKey('sv')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('sv')] ||
      data[I18nHelper.getExplanationLangKey('en')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('en')] ||
      data[I18nHelper.getExplanationLangKey('ru')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('ru')] ||
      data[I18nHelper.getExplanationLangKey('sme')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('sme')] ||
      data[I18nHelper.getExplanationLangKey('ara')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('ara')] ||
      data[I18nHelper.getExplanationLangKey('ukr')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('ukr')] ||
      data[I18nHelper.getExplanationLangKey('som')] !== savedAnswer?.[I18nHelper.getExplanationLangKey('som')]

    const answer: IMediapartnerQuestionAnswer = {
      ...data,
      mediapartner_question_id: question.id,
      candidate_id: Number(candidateId)!,
      election_type: data.election_type ?? (pathname.includes('kuntavaalit') ? ElectionType.Municipality : ElectionType.County)
    }

    if (reasoningChanged || answerChanged) {
      await saveSingle(answer)
    }
  }

  const saveSingle = async (answer: IMediapartnerQuestionAnswer) => {
    try {
      setUpdatebleAnswerId(answer.mediapartner_question_id)
      await mediapartnerStore.updateMPAnswer(answer)
    } catch (error) {
      setErrors({ ...errors, [answer.mediapartner_question_id]: t('textarea.requiredNotAnswered') })
      notificationStore!.createNotification(t('errors.unexpectedError'), 'error')
    }
  }

  const renderQuestion = (question: IMediapartnerQuestion) => {
    switch (question.type) {
      case 'ONE_TO_FIVE':
        return (
          <>
            <OneToFiveInput
              onChange={value => {
                setNestedValue(`${question.id}.answer`)(value)
                updateAnswer(question, { ...item[question.id], answer: value })
              }}
              value={item[question.id]?.answer}
              title={''}
              ballSize={'45px'}
              hideNeutral
              lineThickness={'1px'}
              disabled={readOnly}
            />
            <LinkButton
              onClick={() => {
                setNestedValue(`${question.id}.answer`)(null)
                updateAnswer(question, { ...item[question.id], answer: null })
              }}
              size={'s'}
              intent={'default'}
            >
              {t('additionalQuestionPage.removeAnswer')}
            </LinkButton>
          </>
        )
      case 'YES_NO':
        return (
          <>
            <YesNowWrapper>
              <YesNoQuestion
                onChange={value => {
                  setNestedValue(`${question.id}.answer`)(value)
                  updateAnswer(question, { ...item[question.id], answer: value })
                }}
                answer={item[question.id]?.answer}
                appearance={'BUTTON'}
                valueForYes={5}
                valueForNo={1}
                disabled={readOnly}
              />
            </YesNowWrapper>
            <LinkButton
              onClick={() => {
                setNestedValue(`${question.id}.answer`)(null)
                updateAnswer(question, { ...item[question.id], answer: null })
              }}
              size={'s'}
              intent={'default'}
            >
              {t('additionalQuestionPage.removeAnswer')}
            </LinkButton>
          </>
        )
      default:
        return null
    }
  }


  const nextLink = (() => {
    if (candidate?.election_type === ElectionType.Both && pathname.includes('aluevaalit')) {
      return `/${candidateId}/kuntavaalit/video`
    }
    return `/${candidateId}/yhteenveto`
  })()


  const renderNavActions = () => (
    <NextBackNav
      backTo={() => onNavigate(pathname.replace('mediakumppanit', 'kysymykset'))}
      nextTo={() => onNavigate(nextLink)}
      translations={{
        next: t('nextBackNav.continue'),
        back: t('nextBackNav.back'),
        finish: t('nextBackNav.finish')
      }}
    />
  )

  const onNavigate = async (to: string) => {
    if (!allQuestionsAnswered) {
      const confirm = await confirmPassChoice()
      if (confirm) {
        setNavigateTo(to)
        setNavClickCount(navClickCount + 1)
      }
    } else {
      setNavigateTo(to)
      setNavClickCount(navClickCount + 1)
    }
  }


  const { mediapartnerQuestions } = mediapartnerStore!

  const questions = mediapartnerQuestions.filter((q) => {
    if (pathname.includes('kuntavaalit')) {
      return q.question?.election_type === ElectionType.Municipality
    }
    if (pathname.includes('aluevaalit')) {
      return q.question?.election_type === ElectionType.County
    }
    return false
  })

  const count = questions.length
  const answerCount = answers.filter(a => questions.find(q => q.question?.id === a.mediapartner_question_id)).length

  const allQuestionsAnswered = () => answerCount === count

  let questionNumber = 0

  return (
    <AnswersWrapper
      header={t('additionalQuestionPage.header')}
      continueCondition={answerCount === count}
      navigationPath={navigateTo}
      navigationClickCount={navClickCount}
    >
      <ResponsiveForm>
        {questions
          .filter(mpq => mpq.question)
          .map((q, qI) => {
            questionNumber += 1
            const answerFieldValue =
              answers && answers.find(a => a.mediapartner_question_id === q.question?.id)?.[getAnswerFieldByType(q.question!.type)]
            return (
              <div key={q.question!.id}>
                <CategoryHeader>{I18nHelper.getMediaPartnerName(q.mediapartner, i18n)}</CategoryHeader>
                <PaperWithStatus
                  paperState={determineErrorStatus(
                    getInitialValueByType(q.question!.type),
                    answerFieldValue,
                    errors[q.question!.id],
                    mediapartnerStore.loading && updatebleAnswerId === q.question!.id
                  )}
                >
                  <ComponentWrapper>
                    <QuestionCountSection>
                      <h1>{questionNumber}</h1> <h4>/ {count}</h4>
                    </QuestionCountSection>
                    <QuestionHeader>{I18nHelper.getTranslatedQuestion(q.question!, i18n)}</QuestionHeader>
                    <QuestionContainer>{renderQuestion(q.question!)}</QuestionContainer>
                    <FormMultiLangTextarea
                      onChange={(lang, val) => {
                        if (val.length > REASONING_LIMIT) {
                          setErrors({ ...errors, [q.question!.id]: { ...errors[q.question!.id], textarea: t('textarea.overMax') } })
                        }
                        setNestedValue(`${[q.question!.id]}.explanation_${lang}`)(val)
                      }}
                      languages={{
                        fi: t('textarea.fi'),
                        sv: t('textarea.sv'),
                        en: t('textarea.en'),
                        ru: t('textarea.ru'),
                        sme: t('textarea.sme'),
                        ara: t('textarea.ara'),
                        ukr: t('textarea.ukr'),
                        som: t('textarea.som')
                      }}
                      activeLang={i18n.language}
                      value={{
                        fi: item[q.question!.id]?.[`explanation_fi`],
                        sv: item[q.question!.id]?.[`explanation_sv`],
                        en: item[q.question!.id]?.[`explanation_en`],
                        ru: item[q.question!.id]?.[`explanation_ru`],
                        sme: item[q.question!.id]?.[`explanation_sme`],
                        ara: item[q.question!.id]?.[`explanation_ara`],
                        ukr: item[q.question!.id]?.[`explanation_ukr`],
                        som: item[q.question!.id]?.[`explanation_som`]
                      }}
                      onBlur={async (lang, value) => {
                        if (!value || (value && value.length <= REASONING_LIMIT)) {
                          updateAnswer(q.question!, { ...item[q.question!.id], [`explanation_${lang}`]: value ? value : null })
                        }
                      }}
                      maxChars={REASONING_LIMIT}
                      errorMessage={errors[q.question!.id] && errors[q.question!.id].textarea ? errors[q.question!.id].textarea : undefined}
                      label={t('textarea.reasoningLabel')}
                      disabled={readOnly}
                      scrollShadow={{
                        shadowColor: theme.color.dropShadow
                      }}
                    />
                  </ComponentWrapper>
                </PaperWithStatus>
              </div>
            )
          })}
        <InfoP>{`${t('additionalQuestionPage.infoBeginning')} ${answerCount} / ${count} ${t(
          'additionalQuestionPage.infoEnding'
        )}`}</InfoP>
        {renderNavActions()}
        <FloatingProgress
          saving={mediapartnerStore.loading}
          total={count}
          done={answerCount}
          translations={{ saving: t('floater.saving'), saved: t('floater.saved') }}
        />
      </ResponsiveForm>
    </AnswersWrapper>
  )
})
