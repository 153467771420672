import {CommonTheme, hexVisibilityDark} from '@webscale-oy/vaalikone-common-ui-base'
import { ElectionType } from '../types/election'

export const deepClone = <T>(obj: T): T => JSON.parse(JSON.stringify(obj)) as T

export const checkColorVisibility = (theme: CommonTheme, bgColor?: string) => {
  if (bgColor) {
    return hexVisibilityDark(bgColor) ? theme.textInverse : theme.textSecondary
  }
  return theme.textSecondary
}

export const matchesElection = <T extends {election_type?: ElectionType}>(item: T | undefined, electionType: ElectionType) => {
  if (!item) return false
  return item.election_type === ElectionType.Both || item.election_type === electionType
}

export const matchesMunicipalityElection = <T extends {election_type?: ElectionType}>(item?: T | undefined) => matchesElection(item, ElectionType.Municipality)

export const matchesCountyElection = <T extends {election_type?: ElectionType}>(item?: T | undefined) => matchesElection(item, ElectionType.County)

export const isCountyElection = <T extends {election_type?: ElectionType}>(item?: T) => item?.election_type === ElectionType.County

export const isMunicipalityElection = <T extends {election_type?: ElectionType}>(item?: T) => item?.election_type === ElectionType.Municipality

export const isBothElection = <T extends {election_type?: ElectionType}>(item?: T) => item?.election_type === ElectionType.Both
