import React, { useState } from 'react'
import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { useParams } from 'react-router-dom'
import { Stores } from '../stores'
import { useStores } from '../utils/hooks'
import { AnswersWrapper } from '../templates/AnswersWrapper.template'
import {
  CandidateImages,
  ImageCropper,
  ImageCropperCropData,
  LinkButton,
  PageLoader,
  Paper,
  breakpoints,
  colors,
  spacing,
  subheaderBold,
  textNormal
} from '@webscale-oy/vaalikone-common-ui-base'
import { NextBackNav } from '@webscale-oy/vaalikone-common-ui-candidate'
import { matchesCountyElection } from '../utils/helper.util'

interface IImage {
  fileRef: Blob | null
  src: string | null
}

const initialImageState = {
  fileRef: null,
  src: null
}

const IMG_PREFIX = import.meta.env.VITE_IMG_URL

const CandidateImagePageClass: React.FC = observer(() => {
  const { candidateId } = useParams<{ candidateId: string }>()
  const [image, setImage] = useState<IImage>(initialImageState)
  const [uploading, setIsUploading] = useState(false)
  const { candidateStore, dialogStore, notificationStore } = useStores() as Stores
  const { t } = useTranslation()
  const [onEdit, setOnEdit] = useState(false)
  const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined)
  const [navClickCount, setNavClickCount] = useState<number>(0)
  const readOnly = candidateStore.candidate?.status === 'COMPLETED'
  const candidate = candidateStore.candidate!

  const uploadImage = async (cropData: ImageCropperCropData) => {
    setIsUploading(true)
    try {
      await candidateStore.uploadImage(image.fileRef!, cropData)
    } catch (error) {
      notificationStore.createNotification(t('errors.unexpectedError'), 'error')
    } finally {
      resetImageState()
      setIsUploading(false)
    }
  }

  const onSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const supportedTypes = ['image/jpeg', 'image/tiff', 'image/png', 'image/webp']
      if (!supportedTypes.includes(e.target.files[0].type)) {
        dialogStore?.openDialog(t('error.unsupportedTypeTitle'), t('error.unsupportedTypeBody'), 'error', true)
        return undefined
      }

      if (e.target.files[0].size > 8 * 1000000) {
        dialogStore?.openDialog(t('error.sizeErrorTitle'), t('error.sizeErrorBody'), 'error', true)
        return undefined
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => setImage({ fileRef: e.target.files![0], src: reader.result as string }))
      reader.readAsDataURL(e.target.files[0])
    }
  }

  const resetImageState = () => {
    setImage(initialImageState)
    setOnEdit(false)
  }

  const onNavclick = (to: string) => {
    setNavigateTo(to)
    setNavClickCount(navClickCount + 1)
  }

  return (
    <AnswersWrapper
      header={t('photoPage.mainHeader')}
      continueCondition={!!candidate?.image}
      navigationPath={navigateTo}
      navigationClickCount={navClickCount}
    >
      {uploading ? (
        <LoadingIndicatorBackground>
          <PageLoader wrapperHeight="200px" title={t('photoPage.imageProcessing')} />
        </LoadingIndicatorBackground>
      ) : (
        <ColumnWrapper>
          <PhotoPaper>
            <InfoWrapper hasImage={!!candidate?.image}>
              <SubHeader>{candidate?.image && !onEdit ? t('photoPage.imageUploadedSubHeader') : t('photoPage.info')}</SubHeader>
              {!onEdit && !!candidate?.image ? (
                <>
                  <CandidateImageIntro
                    image={`${IMG_PREFIX}/${candidate?.image}`}
                    typesToUse={[
                      {
                        appearance: 'square',
                        width: '223px',
                        height: '297.33px',
                        order: 1,
                        altText: t('photoPage.altBig'),
                        infoText: t('photoPage.profileText')
                      },
                      {
                        appearance: 'round',
                        width: '24px',
                        height: '24px',
                        order: 2,
                        altText: t('photoPage.altSmall'),
                        infoText: t('photoPage.roundText'),
                        src: `${IMG_PREFIX}/${candidate?.image}_thumb`
                      }
                    ]}
                  />
                </>
              ) : (
                candidate && (
                  <ImageCropper
                    originalImage={image.src !== null ? image.src : undefined}
                    onFinish={uploadImage}
                    resetImageState={resetImageState}
                    fileChange={onSelectFile}
                    disabled={readOnly}
                    imageAspect={3 / 4}
                    onError={e => dialogStore.openDialog(t('error.sizeErrorTitle'), e, 'error', true)}
                    translations={{
                      saveImage: t('photoPage.saveImage'),
                      cancelImage: t('photoPage.cancelImage'),
                      sliderAriaLabel: t('photoPage.sliderAriaLabel'),
                      sliderAriaValue: t('photoPage.sliderAriaValue'),
                      uploadNewImage: t('photoPage.uploadNewImage'),
                      acceptedImgFormats: t('photoPage.acceptedImgFormats'),
                      maxFileSize: t('photoPage.maxFileSize')
                    }}
                  />
                )
              )}
            </InfoWrapper>
          </PhotoPaper>
          {candidate?.image && !onEdit && !readOnly && (
            <NewImageText>
              {t('photoPage.newImageOrContinueBeginning')}
              <LinkButton onClick={() => setOnEdit(true)}>{t('photoPage.newImageOrContinueLink')}</LinkButton>
              {t('photoPage.newImageOrContinueEnding')}
            </NewImageText>
          )}
        </ColumnWrapper>
      )}
      <NextBack
        nextTo={() => onNavclick(`/${candidateId}/${matchesCountyElection(candidate) ? 'aluevaalit' : 'kuntavaalit'}/video`)}
        backTo={() => onNavclick(`/${candidateId}/taustatiedot/taustakysymykset`)}
        translations={{
          next: t('nextBackNav.continue'),
          back: t('nextBackNav.back'),
          finish: t('nextBackNav.finish')
        }}
      />
    </AnswersWrapper>
  )
})

export const CandidateImagePage = CandidateImagePageClass

const ColumnWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`

const PhotoPaper = styled(Paper)`
  background-color: ${colors.grey_25};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  min-width: 760px;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    min-width: 0;
  }
`

const InfoWrapper = styled.div<{ hasImage?: boolean }>`
  display: flex;
  max-width: 640px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SubHeader = styled.h4`
  ${subheaderBold};
`

const NewImageText = styled.span`
  ${textNormal};
  text-align: center;
  display: inline-block;
  margin: ${spacing.space_32}px 0;

  ${LinkButton} {
    display: inline-block;
    color: ${({ theme }) => theme.infoPrimary};
    padding: 0;
    :hover {
      background-color: #fff;
    }
  }
`

const LoadingIndicatorBackground = styled(Paper)`
  background-color: ${colors.grey_25};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  min-width: 760px;
  @media only screen and (max-width: ${breakpoints.mobile}) {
    min-width: 100vw;
  }
`

const NextBack = styled(NextBackNav)`
  background-color: initial;
`

const CandidateImageIntro = styled(CandidateImages)`
  img {
    margin-right: 0 !important;
  }
`
