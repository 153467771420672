import React, { FC } from 'react'
import styled from 'styled-components'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import logo from '../../images/yle_logo_musta.svg'
import { ElectionLogo } from '../components/ElectionLogo'
import { useStores } from '../utils/hooks'
import { Stores } from '../stores'
import { ContentContainer } from '../elements/Layout'
import { BodyText, Button, Stack, breakpoints, colors, spacing, subheaderLight, textH1, textH3, textH5 } from '@webscale-oy/vaalikone-common-ui-base'
import { matchesCountyElection, matchesMunicipalityElection } from '../utils/helper.util'

interface IProps {
  className?: string
}

export const InfoPage: FC<IProps> = ({ className }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { candidateId } = useParams<{ candidateId: string }>()
  const { candidateStore } = useStores() as Stores
  let order = 1

  return (
    <ContentContainer className={className}>
      <ElectionLogo alt={t('infoPage.logoAltText')} src={logo} />
      <Header>
        {t('infoPage.mainHeader')} {candidateStore.candidate?.first_name} {candidateStore.candidate?.last_name}!
      </Header>
      <Stack $space="small" $paddingX="xsmall">
        <InfoWrapper>
          <OrderIndicator>{order++}</OrderIndicator>
          <StepTextWrapper>
            <SubHeader>{t('infoPage.bgSectionHeader')}</SubHeader>
            <Description>{t('infoPage.bgSectionDescription')}</Description>
          </StepTextWrapper>
        </InfoWrapper>
        {
          matchesCountyElection(candidateStore.candidate) && (
            <InfoWrapper>
              <OrderIndicator>{order++}</OrderIndicator>
              <StepTextWrapper>
                <SubHeader>{t('infoPage.countySection')}</SubHeader>
                <Description>{t('infoPage.countySectionDescription')}</Description>
              </StepTextWrapper>
            </InfoWrapper>
          )
        }
        {
          matchesMunicipalityElection(candidateStore.candidate) && (
            <InfoWrapper>
              <OrderIndicator>{order++}</OrderIndicator>
              <StepTextWrapper>
                <SubHeader>{t('infoPage.municipalitySection')}</SubHeader>
                <Description>{t('infoPage.municipalitySectionDescription')}</Description>
              </StepTextWrapper>
            </InfoWrapper>
          )
        }
        <InfoHelpText>{t('infoPage.helpText')}</InfoHelpText>
      </Stack>
      <StartButton onClick={() => navigate(`/${candidateId}/taustatiedot/taustakysymykset`)}>{t('infoPage.startButton')}</StartButton>
    </ContentContainer>
  )
}

const Header = styled.h1`
  ${textH1};
  margin-bottom: ${spacing.space_48}px;
  @media only screen and (max-width: ${breakpoints.mobileS}) {
    font-size: 32px;
  }
`

const Description = styled.p`
  ${subheaderLight};
  margin: 0;
  text-align: left;
`

const InfoWrapper = styled.div`
  display: flex;
  width: 100%;
  border: ${({ theme }) => `1px solid ${colors.grey_100}`};
  border-radius: ${({ theme }) => theme.radiusSmall};
  flex-direction: row;
  padding: ${spacing.space_24}px;
  align-items: center;
  :not(:last-child) {
    margin-bottom: ${spacing.space_24}px;
  }
`

const StartButton = styled(Button)`
  margin: ${spacing.space_40}px 0 0 0;
  min-width: 100px;
  justify-content: center;
  @media only screen and (max-width: ${breakpoints.mobileS}) {
    margin: ${spacing.space_24}px 0 ${spacing.space_48}px 0;
  }
`

const StepTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const OrderIndicator = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #f1f1f1;
  width: 60px;
  height: 60px;
  margin-right: ${spacing.space_24}px;
  flex-shrink: 0;
  ${textH3}
`

const SubHeader = styled.h5`
  ${textH5}
  margin: 0 0 ${spacing.space_4}px 0;
  text-align: left;
`
const InfoHelpText = styled(BodyText)``
