import i18n from 'i18next'
import {initReactI18next} from 'react-i18next'
import axios, {AxiosError} from 'axios'
import { commonI18N } from '@webscale-oy/vaalikone-common-ui-base'
import {combinedTranslations} from '../translations'
import { config } from '../config'

const getTranslations = async (url: string, lang?: string) =>
  axios(url, {headers: {Accept: 'application/json', 'Content-Type': 'application/json'}, method: 'GET'})
    .then(res => (lang ? res.data[lang] : res.data))
    .catch((err: AxiosError) => {
      console.error(err)
      throw err
    })

export const initi18n = async () => {
  const translations = await getTranslations(`${import.meta.env.VITE_API_URL}/translations`)

  const query = window.location.search.substring(1)
  const params = query.split('language=')

  let lang = 'fi'
  if (params.length === 2) {
    if (params[1].slice(0, 3) === 'swe') {
      lang = 'sv'
    }
  }

  if (window.sessionStorage.language) {
    lang = window.sessionStorage.language
  }

  commonI18N.use(initReactI18next).init({
    resources: {
      fi: {translation: combinedTranslations.fi.translation},
      sv: {translation: combinedTranslations.sv.translation}
    },
    lng: lang,
    fallbackLng: 'fi',
    interpolation: {
      escapeValue: false
    },
    fallbackNS: 'translation',
    defaultNS: 'overrides'
  })
  commonI18N.addResourceBundle('fi', 'overrides', translations.fi)
  commonI18N.addResourceBundle('sv', 'overrides', translations.se)
}

commonI18N.on('languageChanged', (lng: string) => {
  document.querySelector('html')?.setAttribute('lang', lng)
  window.sessionStorage.setItem('language', lng)
})

if (config.env !== 'production') {
  // @ts-ignore
  window.i18next = commonI18N
}

export default i18n
