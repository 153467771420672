import styled from 'styled-components'
import {theme} from '../theme'
import {Dropdown, breakpoints, spacing} from '@webscale-oy/vaalikone-common-ui-base'

export const MainWrapper = styled.div`
  min-height: 100vh;
  top: 0;
  position: absolute;
  left: 0;
  right: 0;
  padding-bottom: 200px;
`

export const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${theme.layout.headerHeight}px;
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  max-width: 720px;
  margin: 0 0 ${spacing.space_56}px 0;

  ${Dropdown} {
    text-align: left;
    margin-bottom: ${spacing.space_32}px;
    width: 200px;
  }

  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    margin: 0 ${spacing.space_12}px;
    padding: 0 ${spacing.space_4}px;
  }
`
