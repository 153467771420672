import {action, computed, makeAutoObservable, observable, runInAction} from 'mobx'
import {IZipCodeArea} from '../types/zipCodeArea'
import {NotificationStore} from './NotificationStore'
import {CandidateStatus, ICandidate, VideoDescriptions} from '../types/candidate'
import {authenticatedHeaders, get, post, put} from '../api/methods'
import {ILoginResponse} from '../types/user'
import jwt_decode from 'jwt-decode'
import {ImageCropperCropData} from '@webscale-oy/vaalikone-common-ui-base'
import {ElectionType} from '../types/election'
import {isMunicipalityElection} from '../utils/helper.util'

const VAALIKONE_TOKEN_KEY = 'vaalikone_candidatoken'
export type LOGIN_STATUS = 'loading' | 'unauthorized' | 'logged_in' | 'link_error'

interface JwtToken {
  exp: number
}

const calculateJwtTimeout = (token: string) => {
  const decodedJwt = jwt_decode(token) as JwtToken
  const tokenExpirationMillis = decodedJwt.exp * 1000
  const millisNow = new Date().getTime()
  const jwtTimeout = tokenExpirationMillis - millisNow
  return jwtTimeout
}

export class CandidateStore {
  @observable status: LOGIN_STATUS = 'unauthorized'

  @observable jwt: string | null = null

  @observable candidate: ICandidate | undefined = undefined

  notificationStore: NotificationStore

  private jwtExpirationTimeout: NodeJS.Timeout | undefined = undefined

  constructor(notificationsStore: NotificationStore) {
    this.notificationStore = notificationsStore
    makeAutoObservable(this)
  }

  @action
  async init(candidateId: number): Promise<void> {
    this.status = 'loading'
    let token = sessionStorage.getItem(VAALIKONE_TOKEN_KEY)
    const tokenParams = window.location.search.split('admin_token=')
    if (!token && tokenParams.length === 2) {
      token = tokenParams[1]
      sessionStorage.setItem(VAALIKONE_TOKEN_KEY, token)
    }
    if (token) {
      try {
        this.jwt = token
        this.status = 'loading'
        const candidate = await get<ICandidate>(`${candidateId}/current`, authenticatedHeaders())
        if (!candidate) {
          throw Error('Session has expired')
        }
        runInAction(() => {
          if (token) {
            this.jwtExpirationTimeout = setTimeout(this.logout, calculateJwtTimeout(token))
          }
          this.candidate = candidate
          this.status = 'logged_in'
        })
      } catch (err) {
        runInAction(() => {
          this.status = 'unauthorized'
          this.jwt = null
        })
      }
    } else {
      this.status = 'unauthorized'
    }
  }

  @action
  async login(loginToken: string): Promise<void> {
    this.status = 'loading'
    const sanitizizedToken = loginToken.trim().replace(/\.$/, '')
    const result = await post<ILoginResponse>('login', {login_token: sanitizizedToken})

    runInAction(() => {
      if (result) {
        this.status = 'logged_in'
        this.candidate = result.candidate
        this.jwt = result.token
        this.jwtExpirationTimeout = setTimeout(this.logout, calculateJwtTimeout(result.token))
        sessionStorage.setItem(VAALIKONE_TOKEN_KEY, result.token)
        this.notificationStore.createNotification(`Tervetuloa ${result.candidate.first_name} ${result.candidate.last_name}`, 'success')
      } else {
        this.status = 'unauthorized'
      }
    })
  }

  @action
  async smsToken(token: string): Promise<void> {
    const sanitizizedToken = token.trim().replace(/\.$/, '')
    try {
      await post<string>('smstoken', {login_token: sanitizizedToken})
    } catch (ex) {
      runInAction(() => {
        this.status = 'link_error'
      })
    }
  }

  @action
  async smsLogin(smsToken: string, smsLoginToken: string): Promise<void> {
    this.status = 'loading'
    const result = await post<ILoginResponse>('sms-login', {login_token: smsLoginToken, sms_token: smsToken})

    runInAction(() => {
      if (result) {
        this.status = 'logged_in'
        this.candidate = result.candidate
        this.jwt = result.token
        this.jwtExpirationTimeout = setTimeout(this.logout, calculateJwtTimeout(result.token))
        sessionStorage.setItem(VAALIKONE_TOKEN_KEY, result.token)
        this.notificationStore.createNotification(`Tervetuloa ${result.candidate.first_name} ${result.candidate.last_name}`, 'success')
      } else {
        this.status = 'unauthorized'
      }
    })
  }

  @action
  async uploadImage(image: Blob, cropData: ImageCropperCropData): Promise<void> {
    const form = new FormData()
    form.append('image', image)
    form.append('x', cropData.x.toString())
    form.append('y', cropData.y.toString())
    form.append('width', cropData.width.toString())
    form.append('height', cropData.height.toString())

    const simpleCandidate = await post<ICandidate>(
      `${this.candidate!.id}/photo`,
      form,
      authenticatedHeaders(undefined, 'multipart/form-data')
    )
    runInAction(() => {
      this.candidate = {...this.candidate!, image: simpleCandidate!.image}
    })
  }

  @action
  async getPresignedVideoUrl(election_type: ElectionType): Promise<{videoUploadUrl: string; imageUploadUrl: string; programId: string}> {
    const response = await post<{videoUploadUrl: string; imageUploadUrl: string; programId: string}>(
      `${this.candidate!.id}/videoUploadUrl/${isMunicipalityElection({election_type}) ? 'municipality' : 'county'}`,
      {},
      authenticatedHeaders()
    )
    return response!
  }

  @action
  async updateVideo(programId: string, videoAspect: string, election_type: ElectionType): Promise<ICandidate> {
    const result = await post(
      `${this.candidate!.id}/updateVideo/${isMunicipalityElection({election_type}) ? 'municipality' : 'county'}`,
      {programId, videoAspect},
      authenticatedHeaders()
    )
    runInAction(() => {
      this.candidate = result as ICandidate
    })
    return result as ICandidate
  }

  @action
  async updateVideoDescriptions(videoDescriptions: VideoDescriptions): Promise<ICandidate> {
    await post(`${this.candidate!.id}/updateVideoDescriptions`, {...videoDescriptions}, authenticatedHeaders())
    runInAction(() => {
      this.candidate = {...this.candidate!, ...videoDescriptions}
    })
    return {...this.candidate!, ...videoDescriptions}
  }

  @action
  async updateCandidateZipCode(zipCode: string | null, zipCodeArea?: IZipCodeArea) {
    const candidateId = this.candidate && this.candidate.id
    const result = await post<string | null>(`${candidateId}/background-info/zip-code`, {zipCode}, authenticatedHeaders())
    runInAction(() => {
      if (result) {
        this.candidate = {...this.candidate, zipCode: result, zipCodeArea} as ICandidate
      }
    })
  }

  @action
  async updateTermsAgreed(termsAgreed: boolean) {
    const candidateId = this.candidate && this.candidate.id
    const result = await put(`${candidateId}/update`, {terms_agreed: termsAgreed}, authenticatedHeaders())
    runInAction(() => {
      if (result) {
        this.candidate = {...this.candidate, terms_agreed: termsAgreed} as ICandidate
      }
    })
  }

  @action
  logout = () => {
    this.status = 'unauthorized'
    this.candidate = undefined
    this.jwt = null
    sessionStorage.removeItem(VAALIKONE_TOKEN_KEY)
    if (this.jwtExpirationTimeout) clearTimeout(this.jwtExpirationTimeout)
  }

  @action
  setCandidateStatus = (candidateStatus: CandidateStatus) => {
    runInAction(() => {
      this.candidate = {...this.candidate, status: candidateStatus} as ICandidate
    })
  }

  @computed
  get isAuthenticated() {
    return this.status === 'logged_in'
  }

  @computed
  get candidateId() {
    return this.candidate ? this.candidate.id : undefined
  }
}
