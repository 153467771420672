import React, {FC} from 'react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {color, typography} from '@yleisradio/yle-design-system'
import {useNavigate, useParams} from 'react-router-dom'
import {theme} from '../theme'
import {WelcomeBox} from '../elements/welcome.box'
import {PillButton} from '../elements/Button'
import {InfoBox} from '../elements/Info'

const ReminderPageComponent: FC = () => {
  const navigate = useNavigate()
  const {t} = useTranslation()
  const {candidateId} = useParams<{candidateId: string}>()
  return (
    <Container>
      <WelcomeBox>
        <ReminderText>{t('reminder.title')}</ReminderText>
        <p dangerouslySetInnerHTML={{__html: t('reminder.text')}} />
        <PillButton intent="yleprimary" onClick={() => navigate(`/${candidateId}/info`)}>
          {t('reminder.backToFrontPage')}
        </PillButton>
      </WelcomeBox>
    </Container>
  )
}

export const ReminderPage = ReminderPageComponent

const ReminderText = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: ${theme.fontSize.large};
  font-weight: bold;
  font-family: ${typography.OPEN_SANS_2XL_BOLD_FONT_FAMILY};
  font-size: ${typography.OPEN_SANS_2XL_BOLD_FONT_SIZE};
  width: 100%;
`

const Container = styled.section`
  width: 100%;
  background-size: cover;
  background: #fff center no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  ${InfoBox} {
    margin-top: 10px;
  }

  ${PillButton} {
    align-self: flex-start;
  }

  ${WelcomeBox} {
    padding: 85px 40px 40px 40px;
  }

  p {
    color: ${color.BLACK};
    font-size: ${typography.OPEN_SANS_M_FONT_SIZE};
    margin-bottom: 24px;
    line-height: ${typography.OPEN_SANS_M_LINE_HEIGHT};
  }

  form {
    text-align: center;
  }
  &:after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: ${theme.color.secondary};
    background-size: cover;
    z-index: -2;
    display: block;
    content: ' ';
    clip-path: polygon(0% 0%, 117% 0, 72% 74%, 0% 102%);
  }

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    clip-path: polygon(72% 74%, 100% 27%, 100% 100%, 5% 100%);
    background: ${theme.color.primary.default};
    background-size: cover;
    display: block;
    content: ' ';
    z-index: -1;
    height: 100%;
    opacity: 0.9;
  }
`
