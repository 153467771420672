import {action, makeAutoObservable, runInAction} from 'mobx'

import {IAnswer, IFinalizedResponse} from '../types/answer'
import {NotificationStore} from './NotificationStore'
import {CandidateStore} from './CandidateStore'
import {authenticatedHeaders, get, post} from '../api/methods'
import {commonI18N} from '@webscale-oy/vaalikone-common-ui-base'


export class AnswerStore {
  answers: IAnswer[] = []
  loading = false
  notificationStore: NotificationStore
  candidateStore: CandidateStore

  constructor(notificationsStore: NotificationStore, candidateStore: CandidateStore) {
    this.notificationStore = notificationsStore
    this.candidateStore = candidateStore
    makeAutoObservable(this)
  }

  @action
  async loadAnswers(): Promise<void> {
    const candidateId = this.candidateStore.candidate!.id
    this.loading = true
    const result = await get<IAnswer[]>(`${candidateId}/answers`, authenticatedHeaders())
    runInAction(() => {
      if (result as IAnswer[]) {
        this.answers = result
      }
      this.loading = false
    })
  }

  getAnswer(questionId: number): Partial<IAnswer> | undefined {
    return this.answers.find(c => c.question_id === questionId)
  }

  @action
  async postAnswer(newAnswer: IAnswer) {
    const index = this.answers.findIndex(a => a.question_id === newAnswer.question_id)
    if (index === -1) {
      this.answers.push(newAnswer)
    } else {
      this.answers = this.answers.map(a => (a.question_id === newAnswer.question_id ? newAnswer : a))
    }
    const {candidateId} = this.candidateStore
    const result = await post<IAnswer>(`${candidateId}/answers`, newAnswer, authenticatedHeaders())

    runInAction(() => {
      if (result) {
        this.answers = this.answers.map(a => (a.question_id === result.question_id ? result : a))
        this.notificationStore.createNotification(commonI18N.t('success.answerSaved'), 'success', 3)
      }
      this.loading = false
    })
    return !!result
  }

  @action async finalizeAnswering() {
    const {candidateId} = this.candidateStore
    const result = await post<IFinalizedResponse>(`${candidateId}/answer/finished`, {}, authenticatedHeaders())
    runInAction(() => {
      if (result?.status === 'COMPLETED') {
        this.notificationStore.createNotification(commonI18N.t('success.allAnswersSaved'), 'success')
        this.candidateStore.setCandidateStatus('COMPLETED')
      } else {
        this.notificationStore.createNotification(commonI18N.t('error.publishingFailed'), 'error')
      }
    })
    return result
  }
}
