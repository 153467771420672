import React, { createRef, FC, useEffect, useState } from 'react'
import styled from 'styled-components'
import { AnswersWrapper } from '../templates/AnswersWrapper.template'
import { useStores } from '../utils/hooks'
import { Stores } from '../stores'
import { useTranslation } from 'react-i18next'
import { VideoRecorder } from '../elements/VideoRecorder'
import { useLocation, useParams } from 'react-router-dom'
import { AreenaVideo } from '../elements/video/AreenaVideo'
import { observer } from 'mobx-react'
import { VideoDescriptions } from '../types/candidate'
import { VideoAspect } from '../utils/camera.util'
import { theme } from '../theme'
import { breakpoints, Button, colors, Paper, spacing, subheaderBold, textNormal } from '@webscale-oy/vaalikone-common-ui-base'
import { NextBackNav } from '@webscale-oy/vaalikone-common-ui-candidate'
import { FormMultiLangTextarea } from '../components/FormElements/TextArea/MultiLangTextarea'
import { ElectionType } from '../types/election'
import { isBothElection } from '../utils/helper.util'

type Props = {
  readOnly?: boolean
}

// areena test env ids  {fi: '1-67312481', sv: '1-67312481'}
const introVideoProgramId = { fi: '1-73649883', sv: '1-73649883' }

export const VideoIntroPage: FC<Props> = observer(() => {
  const { dialogStore, candidateStore, notificationStore } = useStores() as Stores
  const [navigateTo, setNavigateTo] = useState<string | undefined>(undefined)
  const [navClickCount, setNavClickCount] = useState<number>(0)
  const [mode, setMode] = useState<'initial' | 'record' | 'videoDescriptions'>('initial')
  const { t, i18n } = useTranslation()
  const lang = i18n.language as 'fi' | 'sv'
  const { candidateId } = useParams<{ candidateId: string }>()
  const candidate = candidateStore.candidate
  const { pathname } = useLocation()
  const video = isBothElection(candidate) && pathname.includes('aluevaalit') ? candidate?.extra_video : candidate?.video
  const [candidateVideoAspect, setCandidateVideoAspect] = useState<VideoAspect | undefined>(candidate?.video_aspect as VideoAspect)
  const [videoDescriptions, setVideoDescriptions] = useState<VideoDescriptions>({})
  const videoAreaRef = createRef<HTMLDivElement>()
  const readOnly = candidateStore.candidate?.status === 'COMPLETED'

  useEffect(() => {
    setCandidateVideoAspect(candidate?.video_aspect as VideoAspect)
    if (candidate) {
      if (isBothElection(candidate) && pathname.includes('aluevaalit')) {
        setVideoDescriptions({
          video_description_fi: candidate.extra_video_description_fi,
          video_description_sv: candidate.extra_video_description_sv,
          video_description_en: candidate.extra_video_description_en,
          video_description_ru: candidate.extra_video_description_ru,
          video_description_sme: candidate.extra_video_description_sme,
          video_description_ara: candidate.extra_video_description_ara,
          video_description_ukr: candidate.extra_video_description_ukr,
          video_description_som: candidate.extra_video_description_som
        })
      } else {
        setVideoDescriptions({
          video_description_fi: candidate.video_description_fi,
          video_description_sv: candidate.video_description_sv,
          video_description_en: candidate.video_description_en,
          video_description_ru: candidate.video_description_ru,
          video_description_sme: candidate.video_description_sme,
          video_description_ara: candidate.video_description_ara,
          video_description_ukr: candidate.video_description_ukr,
          video_description_som: candidate.video_description_som
        })
      }
    }
  }, [candidate, pathname])

  const updateVideo = async (file: Blob, videoAspect: string, image: Blob) => {
    const electionType = pathname.includes('kuntavaalit') ? ElectionType.Municipality : ElectionType.County
    // 1. Get presigned url from Server
    const preSigned = await candidateStore.getPresignedVideoUrl(electionType)
    // 2. Upload the video to S3
    const response = await fetch(preSigned.videoUploadUrl, { method: 'PUT', body: file })

    if (!response.ok) {
      throw Error('Video upload failed')
    }
    // 3. Upload cover image
    await fetch(preSigned.imageUploadUrl, { method: 'PUT', body: image })

    // 4. Notify server that the image has been uploaded
    const withVideo = await candidateStore.updateVideo(preSigned.programId, videoAspect, electionType)
    return withVideo
  }

  const upload = async (file: Blob, videoAspect: string, image: Blob) => {
    try {
      if (!candidate) {
        throw Error('Candidate data not available')
      }
      const result = await updateVideo(file, videoAspect, image)
      if (result) {
        await dialogStore.openDialog(t('videoPage.success'), '', 'success')
        setMode('videoDescriptions')
      }
    } catch (e) {
      await dialogStore.openDialog(t('videoPage.error'), t('videoPage.errorUpload'), 'error')
    }
  }

  const updateVideoDescriptions = async (videoDescriptions: VideoDescriptions) => {
    try {
      if (isBothElection(candidate) && pathname.includes('aluevaalit')) {
        const extraDescriptions = Object.fromEntries(Object.entries(videoDescriptions).map(([key, value]) => [`extra_${key}`, value]))
        await candidateStore.updateVideoDescriptions(extraDescriptions)
      } else {
        await candidateStore.updateVideoDescriptions(videoDescriptions)
      }
      notificationStore.createNotification(i18n.t('success.videoDescriptionSaved'), 'success')
    } catch (e) {
      await dialogStore.openDialog(t('videoPage.error'), t('videoPage.errorUploadDescriptions'), 'error')
    }
  }

  const resetVideoState = () => {
    setMode('record')
  }

  const onNavclick = (to: string) => {
    setNavigateTo(to)
    setNavClickCount(navClickCount + 1)
  }

  const showVideoDescriptionForm = (showHelp: boolean) => (
    <>
      {showHelp && <SubHeader>{t('videoPage.videoDescriptionSubHeader')}</SubHeader>}
      <VideoTextArea>
        <FormMultiLangTextarea
          onChange={(lang, val) => {
            setVideoDescriptions({ ...videoDescriptions, [`video_description_${lang}`]: val === '' ? undefined : val })
          }}
          languages={{
            fi: t('textarea.fi'),
            sv: t('textarea.sv'),
            en: t('textarea.en'),
            ru: t('textarea.ru'),
            sme: t('textarea.sme'),
            ara: t('textarea.ara'),
            ukr: t('textarea.ukr'),
            som: t('textarea.som'),
          }}
          activeLang={i18n.language}
          value={{
            fi: videoDescriptions.video_description_fi,
            sv: videoDescriptions.video_description_sv,
            en: videoDescriptions.video_description_en,
            ru: videoDescriptions.video_description_ru,
            sme: videoDescriptions.video_description_sme,
            ara: videoDescriptions.video_description_ara,
            ukr: videoDescriptions.video_description_ukr,
            som: videoDescriptions.video_description_som
          }}
          onBlur={async (lang, val) => {
            const descriptions = {
              ...videoDescriptions,
              [`video_description_${lang}`]: val === '' ? undefined : val
            }
            setVideoDescriptions(descriptions)
            await updateVideoDescriptions(descriptions)
          }}
          maxChars={2400}
          disabled={readOnly}
          scrollShadow={{
            shadowColor: theme.color.dropShadow
          }}
        />
        {showHelp && <HelpText>{t('videoPage.videoDescriptionHelpText')}</HelpText>}
      </VideoTextArea>
    </>
  )

  const showMode = () => {
    switch (mode) {
      case 'initial':
        return (
          <>
            <SubHeader>{video ? t('videoPage.videoUploadedSubHeader') : t('videoPage.info')}</SubHeader>
            {video ? (
              <AreenaVideo videoId={video} videoAspect={candidateVideoAspect} />
            ) : (
              <AreenaVideo videoId={introVideoProgramId[lang]} />
            )}
            {video && (
              <>
                <Spacer />
                {showVideoDescriptionForm(false)}
              </>
            )}
            <IntroVideoButtons>
              <Button onClick={() => setMode('record')} disabled={readOnly}>
                {video ? t('videoPage.reuploadVideo') : t('videoPage.uploadVideo')}
              </Button>
            </IntroVideoButtons>
          </>
        )
      case 'record':
        return (
          <>
            <SubHeader>{t('videoPage.videoRecordSubHeader')}</SubHeader>
            <VideoRecorder upload={upload} resetVideoState={resetVideoState} disabled={readOnly} topRef={videoAreaRef} />
          </>
        )
      case 'videoDescriptions':
        return showVideoDescriptionForm(true)
    }
  }

  const prevPath = (() => {
    if (pathname.includes('kuntavaalit') && isBothElection(candidate)) {
      return `/${candidateId}/aluevaalit/mediakumppanit`
    }
    return `/${candidateId}/taustatiedot/kuva`
  })()

  return (
    <AnswersWrapper
      header={t('videoPage.mainHeader')}
      continueCondition={!!video}
      navigationPath={navigateTo}
      navigationClickCount={navClickCount}
    >
      <ColumnWrapper ref={videoAreaRef}>
        <PhotoPaper>
          <InfoWrapper>{showMode()}</InfoWrapper>
        </PhotoPaper>
      </ColumnWrapper>
      <NextBack
        nextTo={() => onNavclick(pathname.replace('video', 'kysymykset'))}
        backTo={() => onNavclick(prevPath)}
        translations={{
          next: t('nextBackNav.continue'),
          back: t('nextBackNav.back'),
          finish: t('nextBackNav.finish')
        }}
      />
    </AnswersWrapper>
  )
})

const PhotoPaper = styled(Paper)`
  background-color: ${colors.grey_25};
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 420px;
  min-width: 760px;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    min-width: 0;
    padding: 20px 5px;
  }
`

const SubHeader = styled.h4`
  ${subheaderBold};
`

const InfoWrapper = styled.div`
  display: flex;
  max-width: 640px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const ColumnWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`
const IntroVideoButtons = styled.div`
  height: 50px;
  display: flex;
  flex-direction: column-reverse;
`

const VideoTextArea = styled.div`
  width: 700px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
  }

  @media only screen and (max-width: ${breakpoints.mobileS}) {
    width: 350px;
  }
`
const HelpText = styled.p`
  ${textNormal};
  color: ${colors.grey_400};
`
const Spacer = styled.div`
  height: 48px;
`

const NextBack = styled(NextBackNav)`
  background-color: initial;
  margin-top: ${spacing.space_24}px;
`
