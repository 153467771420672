import {commonUITranslations, mergeDeep} from '@webscale-oy/vaalikone-common-ui-base'

const defaultTranslations = {
  fi: {
    translation: {
      additionalQuestionPage: {
        header: 'Mediakumppanien väitteet',
        infoBeginning: 'Kysymyksistä',
        infoEnding: 'vastattu'
      },
      infoPage: {
        mainHeader: 'Tervetuloa, ',
        logoAltText: 'Yle -logo',
        bgSectionHeader: 'Lisää taustatietosi',
        photoSectionHeader: 'Lataa valokuvasi',
        videoSectionHeader: 'Esittele itsesi videolla',
        questionSectionHeader: 'Vaalikoneväitteet',
        mediapartnersSectionHeader: 'Kumppanit',
        startButton: 'Aloita'
      },
      profilePage: {
        mainHeader: 'Hei',
        picSection: 'Profiilikuva',
        videoSection: 'Esittelyvideo',
        mediapartnerSection: 'Kumppanien väitteet',
        completed: 'Valmis',
        inComplete: 'Kesken',
        edit: 'Muokkaa',
        bgQuestionsSection: 'Lisää taustatietosi',
        questionsSection: 'Väitteet',
        completedLower: 'valmis'
      },
      backgroundQuestions: {
        mainHeader: 'Lisää taustatietosi',
        infoBeginning: 'Kysymyksistä',
        infoEnding: 'vastattu'
      },
      photoPage: {
        mainHeader: 'Lataa valokuvasi',
        imageProcessing: 'Kuvaa käsitellään...',
        saveImage: 'Tallenna',
        cancelImage: 'Peruuta',
        sliderAriaLabel: 'Tarkennuksen liutin',
        sliderAriaValue: 'Tarkennuksen arvo liutuksella',
        uploadNewImage: 'Lataa uusi kuva',
        acceptedImgFormats: 'Hyväksytyt kuvaformaatit ovat: .jpg, .jpeg, ja .png',
        maxFileSize: 'Kuvan maksimikoko on 8 Mt',
        altBig: 'Normaalikokoinen kuva',
        altMedium: 'Keskikokoinen kuva',
        altSmall: 'Pienikokoinen kuva'
      },
      questionPage: {
        header: 'Väitteet',
        infoBeginning: 'Kysymyksistä',
        infoEnding: 'vastattu'
      },
      welcomePage: {
        logoAltText: 'Yle -logo',
        mainHeader: 'Tervetuloa Ylen ehdokaskoneeseen!',
        languageSelect: 'Valitse kieli',
        continueText: 'Seuraava'
      },
      videoPage: {
        mainHeader: 'Esittele itsesi videolla'
      },
      summaryPage: {
        skipped: 'Ohitettu',
        notAnswered: 'Ei vastattu',
        mainHeader: 'Yhteenveto',
        photo: 'Profiilikuva',
        missing: 'Kuva puuttuu',
        edit: 'Muokkaa',
        more: 'lisää',
        video: 'Esittele itsesi videolla',
        bgQuestionsCompleted: 'taustakysymyksistä valmiina',
        bgQuestionsAmountAnswered: 'taustakysymyksistä vastattu',
        questions: 'Väitteet',
        completed: 'valmiina',
        amountAnswered: 'vastattu'
      },
      common: {
        inFinnish: 'Suomeksi',
        inSwedish: 'Ruotsiksi'
      },
      loader: {
        loadingSite: 'Ladataan sivua...'
      },
      footer: {
        contactSectionSubHeader: 'Tarvitsetko apua?',
        faqSectionSubHeader: 'Usein kysytyt kysymykset',
        faqSectiosShowAll: 'Näytä kaikki',
        faqSectionHideRest: 'Piilota loput'
      },
      topNav: {
        logout: 'Kirjaudu ulos',
        profile: 'Profiili',
        title: 'Alue- ja kuntavaalit 2025',
        backgroundInfo: 'Taustatiedot',
        municipalityElection: 'Kuntavaalit',
        countyElection: 'Aluevaalit',
        summary: 'Yhteenveto'
      },
      sectionNav: {
        questions: 'Väitteet',
        video: 'Video',
        photo: 'Valokuva',
        bgQuestions: 'Taustakysymykset',
        mediapartnerQuestions: 'Kumppanit',
        summary: 'Yhteenveto'
      },
      floater: {
        saving: 'Tallennetaan...',
        saved: 'Tallennettu'
      },
      answerOneToFive: {
        label1: 'Täysin eri mieltä',
        label2: 'Osittain eri mieltä',
        label4: 'Osittain samaa mieltä',
        label5: 'Täysin samaa mieltä'
      },
      textarea: {
        reasoningLabel: 'Perustelut',
        fi: 'Suomi',
        sv: 'Ruotsi',
        en: 'Englanti',
        ru: 'Venäjä',
        sme: 'Saame',
        ara: 'Arabia',
        overMax: 'Maksimi merkkimäärä ylittynyt',
        requiredNotAnswered: 'Pakolliseen kysymykseen ei vastattu'
      },
      input: {
        tooBigValue: 'Arvo liian suuri'
      },
      priorityList: {
        done: 'Valmis!',
        notDone: 'Valintoja tekemättä',
        selectMostImportant: 'Valitse tärkein vaihtoehto ensin'
      },
      nextBackNav: {
        continue: 'Seuraava',
        back: 'Takaisin',
        finish: 'Viimeistele'
      },
      errors: {
        candidateServiceClosed: 'Ehdokaskone on suljettu.',
        sessionExpired: 'Istuntosi on umpeutunut.',
        profilelocked: 'Vastausaika on päättynyt ja vastauksesi on nyt lukittu. Onnea vaaleihin!',
        unauthorizedAction: 'Oikeutesi eivät riitä toiminnon suorittamiseen.',
        serviceUnavailable: 'Palvelu on tilapäisesti tavoittamattomissa. Ole hyvä ja kokeile hetken päästä uudestaan.',
        unexpectedError: 'Tapahtui odottamaton virhe. Ole hyvä ja ota yhteyttä ylläpitoon.',
        missingRequiredAnswers: 'Pakollinen kysymys on vastaamatta',
        unsupportedTypeTitle: 'Virheellinen kuvaformaatti',
        unsupportedTypeBody: 'Hyväksytyt kuvaformaatit ovat: .jpg, .jpeg, ja .png',
        sizeErrorTitle: 'Virheellinen koko',
        sizeErrorBody: 'Kuvan maksimikoko tulee olla enintään 8 Mt.',
        failedToSaveField: 'Vastauksen tallentaminen epäonnistui.'
      },
      success: {
        answerSaved: 'Vastauksesi on tallennettu onnistuneesti.'
      }
    }
  },
  sv: {
    translation: {
      additionalQuestionPage: {
        header: 'Våra mediesamarbetspartners frågor',
        infoBeginning: 'Påståenden av',
        infoEnding: 'svarat'
      },
      infoPage: {
        mainHeader: 'Välkommen, ',
        logoAltText: 'Yle logo',
        bgSectionHeader: 'Bakgrundsfrågor',
        photoSectionHeader: 'Profilbild',
        videoSectionHeader: 'Video',
        questionSectionHeader: 'Påståenden',
        mediapartnersSectionHeader: 'Mediesamarbetspartners frågor',
        startButton: 'Starta'
      },
      profilePage: {
        mainHeader: 'Hej',
        picSection: 'Profilbild',
        videoSection: 'Video',
        mediapartnerSection: 'Mediesamarbetspartnersfrågor',
        completed: 'Avslutad',
        inComplete: 'Ännu inte klar',
        edit: 'Redigera',
        bgQuestionsSection: 'Bakgrundsfrågor',
        questionsSection: 'Påståenden',
        completedLower: 'avslutad'
      },
      backgroundQuestions: {
        mainHeader: 'Bakgrundsfrågor',
        infoBeginning: 'Påståenden av',
        infoEnding: 'svarat'
      },
      photoPage: {
        mainHeader: 'Profilbild',
        imageProcessing: 'Din bild bearbetas...',
        saveImage: 'Spara bild',
        cancelImage: 'Avbryt',
        sliderAriaLabel: 'Bildzoom',
        sliderAriaValue: 'Bildzoomvärde',
        uploadNewImage: 'Ladda upp en ny bild',
        acceptedImgFormats: 'Accepterade bildformat: .jpg, .jpeg och .png',
        maxFileSize: 'Maximal bildstorlek är 8 MB',
        altBig: 'Profilbild normal',
        altMedium: 'Profilbild rund medium',
        altSmall: 'Profilbild rund liten'
      },
      questionPage: {
        header: 'Påståenden',
        infoBeginning: 'Påståenden av',
        infoEnding: 'svarat'
      },
      welcomePage: {
        logoAltText: 'Yle logo',
        mainHeader: 'Välkommen till Yles kandidatkompass!',
        languageSelect: 'Välj ett språk',
        continueText: 'Fortsätt'
      },
      videoPage: {
        mainHeader: 'Video'
      },
      summaryPage: {
        skipped: 'Skippad',
        notAnswered: 'Inte besvarad',
        mainHeader: 'Sammanfattning',
        photo: 'Profilbild',
        missing: 'Foto saknas',
        edit: 'Redigera',
        more: 'till',
        video: 'Video',
        bgQuestionsCompleted: 'av de obligatoriska frågorna',
        bgQuestionsAmountAnswered: 'besvarade',
        questions: 'Påståenden',
        completed: 'färdig',
        amountAnswered: 'besvarade'
      },
      common: {
        inFinnish: 'Finska',
        inSwedish: 'Svenska'
      },
      floater: {
        saving: 'Sparar...',
        saved: 'Sparat'
      },
      loader: {
        loadingSite: 'Sidan laddats upp...'
      },
      footer: {
        contactSectionSubHeader: 'Behöver du hjälp?',
        faqSectionSubHeader: 'Frågor och svar',
        faqSectiosShowAll: 'Visa allt',
        faqSectionHideRest: 'Dölj resten'
      },
      topNav: {
        logout: 'Logga ut',
        profile: 'Profil',
        title: 'Riksdagsvalet 2023'
      },
      answerOneToFive: {
        label1: 'Instämmer inte alls',
        label2: 'Håller inte helt med',
        label4: 'Håller med lite',
        label5: 'Håller helt med'
      },
      textarea: {
        reasoningLabel: 'Motivering',
        fi: 'Finska',
        sv: 'Svenska',
        en: 'Engelska',
        ru: 'Ryska',
        sme: 'Samiska',
        ara: 'Arabiska',
        overMax: 'Maximalt antal tecken har överskridits'
      },
      input: {
        tooBigValue: 'Värdet är för högt'
      },
      priorityList: {
        done: 'Färdigt',
        notDone: 'Val saknas',
        selectMostImportant: 'Välj det viktigaste alternativet först'
      },
      sectionNav: {
        questions: 'Påståenden',
        video: 'Video',
        photo: 'Profilbild',
        bgQuestions: 'Bakgrundsfrågor',
        mediapartnerQuestions: 'Mediesamarbetspartners',
        summary: 'Sammandrag'
      },
      nextBackNav: {
        continue: 'Fortsätt',
        back: 'Tillbaka',
        finish: 'Avsluta'
      },
      errors: {
        candidateServiceClosed: 'Kandidatkompassen är stängd.',
        sessionExpired: '	Sessionen är förbi.',
        profilelocked: 'Svarstiden är slut och dina svar är låsta. Lycka till inför valet!',
        unauthorizedAction: 'Rätterna räcker inte till att utföra funktionen.',
        serviceUnavailable: 'Servicen kan inte nås för tillfället. Var så god och försök på nytt om en stund.',
        unexpectedError: 'Ett oväntat fel uppstod. Var så god och kontakta administratören.',
        missingRequiredAnswers: 'Den obligatoriska frågan är obesvarad',
        unsupportedTypeTitle: 'Felaktigt bildformat',
        unsupportedTypeBody: 'Accepterade bildformat: .jpg, .jpeg och .png',
        sizeErrorTitle: 'Bildstorleken är för stor',
        sizeErrorBody: 'Maximal bildstorlek är 8 MB.'
      },
      success: {
        answerSaved: 'Ditt svar har nu sparats.'
      }
    }
  }
}

export const combinedTranslations = mergeDeep(defaultTranslations, commonUITranslations)
