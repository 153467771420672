export interface IElection {
  id: number
  open_for_candidates: boolean
  open_for_new_candidates: boolean
  open_for_party_admin: boolean
  open_for_mediapartner_admin: boolean
  open_for_public: boolean
}

export enum ElectionType {
  Municipality = 'municipality',
  County = 'county',
  Both = 'both'
}
