/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import * as React from 'react'
import {useEffect, useState} from 'react'
import {useLocation, useParams, useNavigate} from 'react-router-dom'
import styled, {css} from 'styled-components'

import {useTranslation} from 'react-i18next'
import {Stores} from '../stores'
import {useStores} from '../utils/hooks'
import {faqs} from '../utils/faqs'
import {observer} from 'mobx-react'
import {
  Dropdown,
  Inline,
  PageLoader,
  Span,
  icons,
  formatCandidateName,
  GridItem,
  layoutSpacing,
  layoutElevation,
  accessibleRemoveButtonStyles,
  Stack,
  breakpoints
} from '@webscale-oy/vaalikone-common-ui-base'
import {Footer, NextBackNav} from '@webscale-oy/vaalikone-common-ui-candidate'
import {awsRum} from '../utils/awsRum.util'
import {Link} from 'react-router-dom'
import {RiListCheck2, RiUser3Fill} from 'react-icons/ri'
import {BsChatLeftTextFill} from 'react-icons/bs'
import {NavLink} from 'react-router-dom'
import {ElectionType} from '../types/election'
import {matchesElection} from '../utils/helper.util'
import logo from '../../images/yle_logo.svg'
import {CloseIcon, MenuIcon} from './Icons'
import {config} from '../config'

interface IProps {
  computedMatch?: any
  children?: React.ReactNode
}

export const PrivateRoute: React.FC<IProps> = observer(({children}) => {
  const [loading, setLoading] = useState<boolean>(false)
  const {candidateId} = useParams<{candidateId: string}>()
  const {candidateStore, questionStore, mediapartnerStore, backgroundAnswerStore, backgroundInfoStore, answerStore, appStateStore} =
    useStores() as Stores
  const {t, i18n} = useTranslation()
  const {pathname} = useLocation()
  const navigate = useNavigate()
  const lang = i18n.language
  const topFaqs = faqs[lang || 'fi'] || []
  const candidate = candidateStore.candidate
  const [showAllFaqs, setShowAllFaqs] = useState(false)
  const [menuOpen, setMenuOpen] = useState(false)

  const showTitle = pathname.includes('tervetuloa') || pathname.includes('info')
  const showMainNavigation = !pathname.includes('tervetuloa') && !pathname.includes('info')

  const logout = () => {
    candidateStore.logout()
    navigate('/tervetuloa')
  }

  useEffect(() => {
    awsRum?.addSessionAttributes({
      userId: candidateId!
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setMenuOpen(false)
  }, [pathname])

  useEffect(() => {
    const loadData = async () => {
      setLoading(true)
      if (!candidateStore.isAuthenticated) {
        await candidateStore.init(Number(candidateId))
      }
      if (candidateStore.isAuthenticated) {
        await questionStore.loadQuestions(Number(candidateId))
        await mediapartnerStore.loadMediapartnerQuestions(Number(candidateId))
        await mediapartnerStore.loadMediapartnerQuestionsAnswers()
        await backgroundAnswerStore.loadAnswers()
        await backgroundInfoStore.loadQuestions(Number(candidateId))
        await answerStore.loadAnswers()

        const requiredBgQuestions = backgroundInfoStore.questions.filter(bgq => bgq.important)
        const requiredBgQuestionsAnswered =
          requiredBgQuestions.every(q => backgroundAnswerStore.answers.find(a => q.id === a.info_id)) &&
          candidateStore.candidate?.municipality_id

        if (requiredBgQuestionsAnswered) {
          appStateStore.setReadySections('taustakysymykset')
        }

        if (questionStore.questionCount === answerStore.answers.length) {
          appStateStore.setReadySections('kysymykset')
        }

        if (candidateStore.candidate?.image) {
          appStateStore.setReadySections('kuva')
        }
        if (mediapartnerStore.mpQuestionCount === mediapartnerStore.mediapartnerQuestionAnswers.length) {
          appStateStore.setReadySections('mediakumppanit')
        }
      }
      setLoading(false)
    }
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateId])

  useEffect(() => {
    if (candidateStore.status === 'unauthorized') {
      window.location.replace('/')
    }
    if (candidateStore.candidate?.status === 'COMPLETED' && window.location.search.includes('admin_token=')) {
      window.location.replace(`/${candidateStore.candidate?.id}/yhteenveto`)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [candidateStore.status])

  if (loading) {
    return (
      <LoadContainer>
        <PageLoader title={t('loader.loadingSite')!} />
      </LoadContainer>
    )
  }

  const topPath = {
    taustatiedot: t('topNav.backgroundInfo'),
    aluevaalit: t('topNav.countyElection'),
    kuntavaalit: t('topNav.municipalityElection'),
    yhteenveto: t('topNav.summary')
  }[pathname.split('/')[2]]

  const subPath = {
    video: t('sectionNav.video'),
    kysymykset: t('sectionNav.questions'),
    mediakumppanit: t('sectionNav.mediapartnerQuestions'),
    taustakysymykset: t('sectionNav.bgQuestions'),
    kuva: t('sectionNav.photo')
  }[pathname.split('/')[3]]

  return (
    <MainWrapper>
      <TopNavBar $menuOpen={menuOpen} $showTitle={showTitle} $showMainNavigation={showMainNavigation}>
        <LanguageSelector>
          <img src={logo} alt="" height={38} width={38} />
          <Dropdown
            selected={i18n.language}
            onSelect={i18n.changeLanguage}
            options={[
              {key: 'fi', title: t('common.inFinnish')},
              {key: 'sv', title: t('common.inSwedish')}
            ].concat(config.env !== 'production' ? [{key: 'cimode', title: 'Avaimet'}] : [])}
          />
        </LanguageSelector>
        <GridItem>
          <MobileNav $space="xsmall" $alignY="center">
            <img src={logo} alt="" height={38} width={38} />
            <Span>{topPath}</Span>
            {subPath && (
              <>
                <Span>&gt;</Span>
                <Span>{subPath}</Span>
              </>
            )}
            {showMainNavigation && (
              <IconButton style={{marginLeft: 'auto'}} onClick={() => setMenuOpen(!menuOpen)}>
                {menuOpen ? <CloseIcon /> : <MenuIcon />}
              </IconButton>
            )}
          </MobileNav>
          {showTitle && (
            <Inline $align="center">
              <Span as="h1" $fontSize="lg" $fontWeight="bold">
                {t('topNav.title')}
              </Span>
            </Inline>
          )}
          {showMainNavigation && (
            <NavList>
              <Stack>
                <StyledLink to={`/${candidateId}/taustatiedot`}>
                  <RiUser3Fill />
                  <Span>{t('topNav.backgroundInfo')}</Span>
                </StyledLink>
                <MobileOnly>
                  <Stack $paddingLeft="gutter">
                    <StyledLink to={`/${candidateId}/taustatiedot/taustakysymykset`}>
                      <Span>{t('sectionNav.bgQuestions')}</Span>
                    </StyledLink>
                    <StyledLink to={`/${candidateId}/taustatiedot/kuva`}>
                      <Span>{t('sectionNav.photo')}</Span>
                    </StyledLink>
                  </Stack>
                </MobileOnly>
              </Stack>
              {matchesElection(candidate, ElectionType.County) && (
                <Stack>
                  <StyledLink to={`/${candidateId}/aluevaalit`}>
                    <BsChatLeftTextFill />
                    <Span>{t('topNav.countyElection')}</Span>
                  </StyledLink>
                  <MobileOnly>
                    <Stack $paddingLeft="gutter">
                      <StyledLink to={`/${candidateId}/aluevaalit/video`}>
                        <Span>{t('sectionNav.video')}</Span>
                      </StyledLink>
                      <StyledLink to={`/${candidateId}/aluevaalit/kysymykset`}>
                        <Span>{t('sectionNav.questions')}</Span>
                      </StyledLink>
                      {mediapartnerStore.hasCountyQuestions && (
                        <StyledLink to={`/${candidateId}/aluevaalit/mediakumppanit`}>
                          <Span>{t('sectionNav.mediapartnerQuestions')}</Span>
                        </StyledLink>
                      )}
                    </Stack>
                  </MobileOnly>
                </Stack>
              )}
              {matchesElection(candidate, ElectionType.Municipality) && (
                <Stack>
                  <StyledLink to={`/${candidateId}/kuntavaalit`}>
                    <BsChatLeftTextFill />
                    <Span>{t('topNav.municipalityElection')}</Span>
                  </StyledLink>
                  <MobileOnly>
                    <Stack $paddingLeft="gutter">
                      <StyledLink to={`/${candidateId}/kuntavaalit/video`}>
                        <Span>{t('sectionNav.video')}</Span>
                      </StyledLink>
                      <StyledLink to={`/${candidateId}/kuntavaalit/kysymykset`}>
                        <Span>{t('sectionNav.questions')}</Span>
                      </StyledLink>
                      {mediapartnerStore.hasMunicipalityQuestions && (
                        <StyledLink to={`/${candidateId}/kuntavaalit/mediakumppanit`}>
                          <Span>{t('sectionNav.mediapartnerQuestions')}</Span>
                        </StyledLink>
                      )}
                    </Stack>
                  </MobileOnly>
                </Stack>
              )}
              <StyledLink to={`/${candidateId}/yhteenveto`}>
                <RiListCheck2 />
                <Span>{t('topNav.summary')}</Span>
              </StyledLink>
              <MobileOnly>
                <Inline>
                  <PlainButton onClick={() => i18n.changeLanguage('fi')}>
                    <Span>{t('common.inFinnish')}</Span>
                  </PlainButton>
                </Inline>
                <Inline>
                  <PlainButton onClick={() => i18n.changeLanguage('sv')}>
                    <Span>{t('common.inSwedish')}</Span>
                  </PlainButton>
                </Inline>
                <Inline $paddingTop="gutter" $alignY="center" $space="xsmall" $align="space-between" $paddingRight="xsmall">
                  <Link to={`/${candidateId}/yhteenveto`}>
                    <Span>{candidate && formatCandidateName(candidate as any)}</Span>
                  </Link>
                  <IconButton onClick={logout}>
                    <icons.LogoutIcon title={t('topNav.logout')} height={26} />
                  </IconButton>
                </Inline>
              </MobileOnly>
            </NavList>
          )}
        </GridItem>
        <DesktopOnly>
          <Inline $alignY="center" $space="xsmall" $align="flex-end" $paddingRight="xsmall" $wrap="nowrap">
            <Link to={`/${candidateId}/yhteenveto`}>
              <Span>{candidate && formatCandidateName(candidate as any)}</Span>
            </Link>
            <IconButton onClick={logout}>
              <icons.LogoutIcon title={t('topNav.logout')} height={26} />
            </IconButton>
          </Inline>
        </DesktopOnly>
      </TopNavBar>
      <MainContainer>
        {children}
        <StyledFooter
          onShowAllClick={() => setShowAllFaqs(true)}
          onHideRestClick={() => setShowAllFaqs(false)}
          faqOptions={showAllFaqs ? topFaqs : topFaqs.slice(0, 5)}
          translations={{
            leftHeader: t('footer.contactSectionSubHeader'),
            leftText: (
              <>
                {t('footer.contactSectionText')}
                <a
                  href="#"
                  onClick={e => {
                    e.preventDefault()
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.BE_API = window.BE_API || {}
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    window.BE_API.openChatWindow()
                  }}
                >
                  {t('footer.contactSectionChatText')}
                </a>
              </>
            ),
            rightHeader: t('footer.faqSectionSubHeader')!,
            showAll: t('footer.faqSectiosShowAll')!,
            hideRest: t('footer.faqSectionHideRest')!
          }}
        />
      </MainContainer>
    </MainWrapper>
  )
})

const LanguageSelector = styled.div`
  display: flex;
  align-items: center;
`

const MobileNav = styled(Inline)``

const MobileOnly = styled.div`
  display: block;
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`

const DesktopOnly = styled.div`
  display: none;
  @media screen and (min-width: ${breakpoints.tablet}) {
    display: block;
  }
`

const StyledLink = styled(NavLink)`
  display: inline-flex;
  align-items: center;
  gap: ${layoutSpacing.xsmall};
`

const NavList = styled.ul`
  padding-top: ${layoutSpacing.large} !important;
  padding-bottom: ${layoutSpacing.large} !important;
  padding-left: ${layoutSpacing.small} !important;
  list-style: none;
  margin: 0;
  display: flex;
  gap: ${layoutSpacing.small};
  flex-direction: column;
  a svg {
    display: none;
  }
  overflow: hidden;
  @media screen and (min-width: ${breakpoints.tablet}) {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    flex-direction: row;
    justify-content: center;
    a svg {
      display: block;
    }

    a.active {
      border-bottom: 1px solid black;
    }
  }
`

const PlainButton = styled.button`
  ${accessibleRemoveButtonStyles};
  padding: 0;
  margin: 0;
`

const IconButton = styled(PlainButton)`
  height: 26px;
  width: 26px;
`

const TopNavBar = styled.nav<{$menuOpen: boolean; $showTitle: boolean; $showMainNavigation: boolean}>`
  z-index: ${Number.MAX_SAFE_INTEGER};
  position: fixed;
  width: 100%;
  padding: ${layoutSpacing.xsmall};
  background-color: white;
  top: 0;
  display: grid;
  box-shadow: ${layoutElevation.small};
  align-items: center;
  ${LanguageSelector} {
    display: none;
    * {
      border: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
  }

  @media screen and (min-width: ${breakpoints.tablet}) {
    grid-template-columns: 200px auto 200px;
    ${LanguageSelector} {
      display: flex;
    }
    ${MobileNav} {
      display: none;
    }
    ${NavList} {
      height: auto !important;
    }
  }

  ${({$menuOpen}) =>
    !$menuOpen &&
    css`
      ${NavList}:not(:focus-within) {
        height: 0;
        padding: 0 !important;
      }
    `}

  ${({$menuOpen}) =>
    $menuOpen &&
    css`
      ${NavList} {
        height: auto;
      }
    `}

  ${({$showTitle}) =>
    $showTitle &&
    css`
      img {
        display: none;
      }
    `}
`

const MainWrapper = styled.div`
  padding-top: 86px;
  min-height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  ${NextBackNav} {
    button:first-of-type:hover {
      color: white;
    }
  }
`

const MainContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const LoadContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`
const StyledFooter = styled(Footer)`
  & a {
    color: rgb(56, 105, 211);
  }
`
