import { action, computed, makeAutoObservable, runInAction } from 'mobx'
import { NotificationStore } from './NotificationStore'
import { IQuestionCategory } from '../types/question-category'
import { IQuestion } from '../types/question'
import { authenticatedHeaders, get } from '../api/methods'
import { isCountyElection, isMunicipalityElection, matchesCountyElection, matchesMunicipalityElection } from '../utils/helper.util'
import { isDefined } from '@webscale-oy/vaalikone-common-ui-base'

export class QuestionStore {
  categories: IQuestionCategory[] = []
  municipalityCategories: IQuestionCategory[] = []
  countyCategories: IQuestionCategory[] = []
  loading = false
  questions: IQuestion[] = []
  notificationStore: NotificationStore

  constructor(notificationsStore: NotificationStore) {
    this.notificationStore = notificationsStore
    makeAutoObservable(this)
  }

  @action
  async loadQuestions(candidateId: number): Promise<void> {
    this.loading = true
    const categories = await get<IQuestionCategory[]>(`${candidateId}/questions`, authenticatedHeaders())
    runInAction(() => {
      this.categories = categories
      this.municipalityCategories = categories.map(c => {
        if (matchesMunicipalityElection(c)) {
          return {
            ...c,
            questions: c.questions.filter(isMunicipalityElection)
          }
        }
        return undefined
      }).filter(isDefined)
      this.countyCategories = categories.map(c => {
        if (matchesCountyElection(c)) {
          return {
            ...c,
            questions: c.questions.filter(isCountyElection)
          }
        }
        return undefined
      }).filter(isDefined)
      this.loading = false
      this.questions = categories.flatMap(c => c.questions)
    })
  }

  @computed
  get questionCount(): number {
    return this.categories.reduce((sum, c) => c.questions.length + sum, 0)
  }

  getCategory(categoryId: number) {
    return this.categories.find(c => c.id === categoryId)
  }

  getQuestionForIndex(index: number): IQuestion | undefined {
    let counter = 0
    let question
    this.categories.forEach(c =>
      c.questions.forEach(q => {
        if (counter === index) {
          question = q
        }
        counter += 1
      })
    )
    return question
  }
}
