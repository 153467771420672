import {action, makeAutoObservable, runInAction} from 'mobx'
import {NotificationStore} from './NotificationStore'
import {CandidateStore} from './CandidateStore'
import {authenticatedHeaders, del, get, post} from '../api/methods'
import {IBackgroundAnswer} from '../types/backgroundquestion'
import {commonI18N} from '@webscale-oy/vaalikone-common-ui-base'

export class BackgroundAnswerStore {
  answers: IBackgroundAnswer[] = []
  loading = false
  candidateStore: CandidateStore
  notificationStore: NotificationStore

  constructor(notificationStore: NotificationStore, candidateStore: CandidateStore) {
    this.notificationStore = notificationStore
    this.candidateStore = candidateStore
    makeAutoObservable(this)
  }

  @action
  async loadAnswers() {
    const {candidateId} = this.candidateStore
    this.loading = true
    const result = await get<IBackgroundAnswer[]>(`${candidateId}/background-info/answers`, authenticatedHeaders())
    runInAction(() => {
      if (result) {
        this.answers = result
      }
      this.loading = false
    })
  }

  @action
  async postAnswers(newAnswers: IBackgroundAnswer[]) {
    this.answers = newAnswers
    const {candidateId} = this.candidateStore
    const result = await post<IBackgroundAnswer[]>(`${candidateId}/background-info/answers`, this.answers, authenticatedHeaders())

    runInAction(() => {
      if (result) {
        this.answers = result
        this.notificationStore.createNotification(commonI18N.t('success.answerSaved'), 'success')
      }
      this.loading = false
    })
    return !!result
  }

  @action
  async postAnswer(newAnswer: IBackgroundAnswer) {
    const index = this.answers.findIndex(a => a.info_id === newAnswer.info_id)
    if (index === -1) {
      this.answers.push(newAnswer)
    } else {
      this.answers = this.answers.map(a => (a.info_id === newAnswer.info_id ? newAnswer : a))
    }
    const {candidateId} = this.candidateStore
    const result = await post<IBackgroundAnswer>(`${candidateId}/background-info/answers`, this.answers, authenticatedHeaders())

    runInAction(() => {
      if (result) {
        this.answers = this.answers.map(a => (a.info_id === result.info_id ? result : a))
        this.notificationStore.createNotification(commonI18N.t('success.answerSaved'), 'success', 3)
      }
      this.loading = false
    })
    return !!result
  }

  @action
  async deleteAnswer(id: number) {
    const {candidateId} = this.candidateStore
    const result = await del<boolean>(`${candidateId}/background-info/answers/${id}`, authenticatedHeaders())

    runInAction(() => {
      if (result) {
        this.answers = this.answers.filter(a => a.info_id !== id)
        this.notificationStore.createNotification(commonI18N.t('success.answerSaved'), 'success', 3)
      }
      this.loading = false
    })
    return !!result
  }
}
