import {observer} from 'mobx-react'
import React, {FC} from 'react'
import styled from 'styled-components'
import {FiAlertCircle, FiCheck, FiInfo} from 'react-icons/fi'
import {DialogStore, DialogType} from '../stores/DialogStore'
import {ConfirmationModal} from './Modal'
import {Button, Intent, LinkButton} from '../elements/Button'
import {theme} from '../theme'
import {commonI18N} from '@webscale-oy/vaalikone-common-ui-base'

interface IProps {
  dialogStore: DialogStore
  className?: string
}

export const Dialogs: FC<IProps> = observer(props => {
  const getIcon = (type?: DialogType) => {
    switch (type) {
      case 'warning':
      case 'error':
        return <FiAlertCircle size={26} />
      case 'success':
        return <FiCheck size={26} />
      default:
        return <FiInfo size={26} />
    }
  }

  const continueButtonText = (type: DialogType, translationKey?: string) => {
    if (translationKey) {
      return commonI18N.t(translationKey)
    }
    if (type === 'warning') {
      return commonI18N.t('dialogModal.confirmContinue')
    }
    return commonI18N.t('dialogModal.continue')
  }

  return (
    <div className={props.className}>
      {props.dialogStore.dialogs.map(modal => (
        <Dialog key={modal.id} width="auto" height="auto" isOpen type={modal.type} onKeyChange={() => modal.onClose(false)}>
          {{
            header: (
              <DialogHeader>
                <DialogHeaderIcon>{getIcon(modal.type)}</DialogHeaderIcon>
                <DialogHeaderText tabIndex={0}>{modal.header}</DialogHeaderText>
              </DialogHeader>
            ),
            body: modal.body,
            actions: [
              !modal.hideCancelButton && (
                <LinkButton key="cancel" intent="default" onClick={() => modal.onClose(false)}>
                  {commonI18N.t('dialogModal.cancel')}
                </LinkButton>
              ),
              !modal.hideContinueButton && (
                <Button key="ok" intent={modal.type as Intent} onClick={() => modal.onClose(true)}>
                  {continueButtonText(modal.type, modal.continueTranslationKey)}
                </Button>
              )
            ]
          }}
        </Dialog>
      ))}
    </div>
  )
})

const DialogHeader = styled.div`
  display: flex;
  align-items: center;
`
const DialogHeaderIcon = styled.div`
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    fill: #fff;
  }
`
const DialogHeaderText = styled.div`
  color: ${theme.color.textDark};
  padding: 0 10px;
  font-weight: bold;
`
const Dialog = styled(ConfirmationModal)<{type: DialogType}>`
  .vaalikone-modal__header {
    border-top-color: ${props => getBorderColor(props.type)};
    height: 80px;
  }
  .vaalikone-modal__body {
    padding: 0 54px;
    max-width: 400px;
    line-height: 24px;
  }

  ${DialogHeaderIcon} {
    background-color: ${props => getBackgroundColor(props.type)};
    color: ${props => getColor(props.type)};
  }
`
function getBorderColor(type?: DialogType) {
  switch (type) {
    case 'error':
      return theme.color.error.default
    case 'success':
      return theme.color.success.light
    case 'warning':
      return theme.color.warning.light
    default:
      return theme.color.primary.light
  }
}
function getColor(type?: DialogType) {
  switch (type) {
    case 'error':
      return theme.color.error.default
    case 'success':
      return theme.color.success.darkest
    case 'warning':
      return theme.color.warning.dark
    default:
      return theme.color.primary.default
  }
}
function getBackgroundColor(type?: DialogType) {
  switch (type) {
    case 'error':
      return theme.color.error.lightest
    case 'success':
      return theme.color.success.lightest
    case 'warning':
      return theme.color.warning.light
    default:
      return theme.color.primary.lightest
  }
}
