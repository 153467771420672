import {IBackgroundQuestion, IBgType} from '../types/backgroundquestion'
import {IQuestionType} from '../types/question'

export const getBqAnswerFieldByType = (type: IBgType, multi?: boolean) => {
  switch (type) {
    case 'SIMPLE_TEXT':
      return 'answer_text_fi'
    case 'SELECT':
      return multi ? 'answer_options' : 'numeric_answer'
    case 'NUMERIC':
    case 'SLIDER':
    case 'YES_NO':
      return 'numeric_answer'
    case 'LINK':
      return 'link_answer'
    case 'FREE_TEXT':
    case 'MUNICIPALITY':
    default:
      return 'answer_text_fi'
  }
}

export const getInitialBqValueByType = (type: IBgType, multi?: boolean) => {
  switch (type) {
    case 'NUMERIC':
    case 'MUNICIPALITY':
    case 'SLIDER':
      return undefined
    case 'SELECT':
      return multi ? [] : undefined
    case 'SIMPLE_TEXT':
    case 'FREE_TEXT':
    default:
      return ''
  }
}

export const getRequiredQuestionIds = (questions: IBackgroundQuestion[]) => questions.filter(q => q.important).map(q => q.id)

export const getValidAnswers = (form: {[key: number]: any}, errors: {[key: number]: any}) =>
  Object.keys(form)
    .map(key => parseInt(key, 10))
    .filter(key => errors[key] === false)

export const getAnswerFieldByType = (type: IQuestionType) => {
  switch (type) {
    case 'PRIORITY_LIST':
      return 'options_answer'
    case 'ONE_TO_FIVE':
    default:
      return 'answer'
  }
}

export const getInitialValueByType = (type: IQuestionType) => {
  switch (type) {
    case 'PRIORITY_LIST':
      return []
    case 'ONE_TO_FIVE':
    default:
      return undefined
  }
}
