import * as React from 'react'
import {observer} from 'mobx-react'
import styled from 'styled-components'
import {useTranslation} from 'react-i18next'
import {useNavigate, useParams} from 'react-router-dom'
import {Stores} from '../stores'
import {IQuestion, IMediapartnerQuestion} from '../types/question'
import {useMunicipalities, useStores} from '../utils/hooks'
import {AnswersWrapper} from '../templates/AnswersWrapper.template'
import {I18nHelper} from '../i18n/i18nHelper'
import {IBackgroundQuestion} from '../types/backgroundquestion'
import {RiVideoChatLine, RiCompass3Line, RiQuestionAnswerLine} from 'react-icons/ri'
import {AreenaVideo} from '../elements/video/AreenaVideo'
import {Notification} from '../components/Notifications'
import {NextBackNav} from '@webscale-oy/vaalikone-common-ui-candidate'
import {Span, Stack, SummaryCard, breakpoints, spacing, textBold, textNormal} from '@webscale-oy/vaalikone-common-ui-base'
import {ElectionType} from '../types/election'
import {
  isBothElection,
  isCountyElection,
  isMunicipalityElection,
  matchesCountyElection,
  matchesMunicipalityElection
} from '../utils/helper.util'

const IMG_PREFIX = import.meta.env.VITE_IMG_URL

export const SummaryPageClass: React.FC = observer(() => {
  const {answerStore, mediapartnerStore, backgroundInfoStore, questionStore, backgroundAnswerStore, candidateStore, dialogStore} =
    useStores() as Stores
  const navigate = useNavigate()
  const {candidateId} = useParams<{candidateId: string}>()
  const {t, i18n} = useTranslation()
  const readOnly = candidateStore.candidate?.status === 'COMPLETED'
  const {municipalityOptions} = useMunicipalities()

  const oneToFiveTranslation = (value?: number) => {
    switch (value) {
      case 1:
        return t('components.oneToFiveInput.optionLabel_1')
      case 2:
        return t('components.oneToFiveInput.optionLabel_2')
      case 3:
        return t('components.oneToFiveInput.optionLabel_3')
      case 4:
        return t('components.oneToFiveInput.optionLabel_4')
      case 5:
        return t('components.oneToFiveInput.optionLabel_5')
      case null:
        return t('summaryPage.skipped')
      default:
        return undefined
    }
  }

  const questionAnswer = (question: IQuestion) => {
    const answer = answers.find(a => a.question_id === question.id)
    if (question.type === 'PRIORITY_LIST') {
      if (answer?.options_answer === null) {
        return <ThemeParagraph key={`question_${question.id}_answer_skipped`}>{t('summaryPage.skipped')}</ThemeParagraph>
      }
      if (answer?.options_answer?.length === question.options?.length) {
        return answer?.options_answer?.map(a => (
          <ThemeParagraph key={`question_${question.id}_answer_${a}`}>
            {I18nHelper.getTranslatedPriorityListOption(question.options!.find(q => a === q.id)!, i18n)}
          </ThemeParagraph>
        ))
      }
      return undefined
    }
    if (question.type === 'YES_NO') {
      if (answer?.answer === null) {
        return t('summaryPage.skipped')
      }
      if (answer?.answer === 1) {
        return t('commonUI.generic.no')
      }
      if (answer?.answer === 5) {
        return t('commonUI.generic.yes')
      }
    }
    return oneToFiveTranslation(answer?.answer || undefined)
  }

  const mediaquestionAnswer = (question?: IMediapartnerQuestion) => {
    if (!question) {
      return undefined
    }
    const answer = mediapartnerQuestionAnswers.find(a => a.mediapartner_question_id === question.id)
    if (question.type === 'YES_NO') {
      yesNoQuestionAnswer(answer?.answer)
    }
    return oneToFiveTranslation(answer?.answer || undefined)
  }

  const yesNoQuestionAnswer = (answer?: number | null) => {
    if (answer === null) {
      return t('summaryPage.skipped')
    }
    if (answer === 1) {
      return t('commonUI.generic.no')
    }
    if (answer === 5) {
      return t('commonUI.generic.yes')
    }
  }

  const bgQuestionAnswer = (question: IBackgroundQuestion) => {
    const answer = bgQuestionAnswers.find(a => a.info_id === question.id)
    if (question.type === 'NUMERIC') return answer?.numeric_answer
    if (question.type === 'LINK') return answer?.link_answer
    if (question.type === 'SELECT') {
      if (question.multi && (!answer?.answer_options || answer?.answer_options?.length === 0)) {
        return undefined
      }
      if (!question.multi && !answer?.numeric_answer) {
        return undefined
      }
      if (question.multi) {
        return answer?.answer_options?.map(a => (
          <ThemeParagraph key={`bgquestion_${question}_answer_${a}`}>
            {I18nHelper.getTranslatedBackgroundQuestionOption(question.options!.find(q => a === q.id)!, i18n)}
          </ThemeParagraph>
        ))
      } else {
        return (
          <ThemeParagraph key={`bgquestion_${question}_answer_${answer?.numeric_answer}`}>
            {I18nHelper.getTranslatedBackgroundQuestionOption(question.options!.find(q => answer?.numeric_answer === q.id)!, i18n)}
          </ThemeParagraph>
        )
      }
    }
    if (question.type === 'YES_NO') {
      if (!answer?.numeric_answer) return undefined
      return (
        <ThemeParagraph key={`bgquestion_${question}_answer_${answer.numeric_answer}`}>
          {yesNoQuestionAnswer(answer.numeric_answer)}
        </ThemeParagraph>
      )
    }
    if (question.type === 'MUNICIPALITY') {
      if (!answer?.answer_text_fi) return undefined
      return (
        <ThemeParagraph key={`bgquestion_${question}_answer_${answer.answer_text_fi}`}>
          {municipalityOptions.find(m => m.key === answer.answer_text_fi)?.title}
        </ThemeParagraph>
      )
    }
    if (question.type === 'SLIDER') {
      if (!answer?.numeric_answer) return undefined
      return (
        <ThemeParagraph key={`bgquestion_${question}_answer_${answer?.numeric_answer}`}>
          {I18nHelper.getTranslatedBackgroundQuestionOption(question.options!.find(q => answer?.numeric_answer === q.target_value)!, i18n)}
        </ThemeParagraph>
      )
    }
    return answer?.[I18nHelper.getAnswerLangKey(i18n)]
  }

  const electionPromiseText = (bgq: IBackgroundQuestion) => {
    // hotfix to support text breaks in election_promises bg question in akv2025
    const answer = bgQuestionAnswer(bgq) ?? ''
    return <TextWrapper dangerouslySetInnerHTML={{__html: answer}} lineBreak />
  }

  const bgAnswerIndicator = (bgq: IBackgroundQuestion) => {
    const isElectionPromiseBgQ = bgq.public_field?.toLowerCase().includes('election_promise')
    if (bgq.important) {
      if (bgQuestionAnswer(bgq)) {
        if (isElectionPromiseBgQ) {
          return electionPromiseText(bgq)
        }
        return <TextWrapper>{bgQuestionAnswer(bgq)}</TextWrapper>
      } else {
        return (
          <TextWrapper>
            <NotAnswered>{t('summaryPage.notAnswered')}</NotAnswered>
          </TextWrapper>
        )
      }
    }
    if (bgQuestionAnswer(bgq)) {
      if (isElectionPromiseBgQ) {
        console.log(true)
        return electionPromiseText(bgq)
      }
      return <TextWrapper>{bgQuestionAnswer(bgq)}</TextWrapper>
    } else {
      return (
        <TextWrapper>
          <NotAnsweredNotRequired>{t('summaryPage.notAnswered')}</NotAnsweredNotRequired>
        </TextWrapper>
      )
    }
  }

  const {categories, municipalityCategories, countyCategories} = questionStore
  const {candidate} = candidateStore
  const {mediapartnerQuestions, mediapartnerQuestionAnswers} = mediapartnerStore
  const {answers: bgQuestionAnswers} = backgroundAnswerStore
  const {answers} = answerStore
  const mediapartnerQuestionsWithValidQuestion = (electionType: ElectionType) =>
    mediapartnerQuestions.filter(q => !!q.question && q.question.election_type === electionType)

  const bgQuestions = (() => {
    const questions = backgroundInfoStore.questions
    const candidate = candidateStore.candidate
    if (!candidate) {
      return []
    }
    if (isBothElection(candidate)) {
      return questions
    }
    if (isMunicipalityElection(candidate)) {
      return questions.filter(matchesMunicipalityElection)
    }
    if (isCountyElection(candidate)) {
      return questions.filter(matchesCountyElection)
    }
    return []
  })()

  const municipalityQuestions = municipalityCategories.flatMap(c => c.questions)
  const countyQuestions = countyCategories.flatMap(c => c.questions)

  const requiredBgQuestions = bgQuestions.filter(bgQ => bgQ.important)
  const allBgQuestionsAnswered = () => requiredBgQuestions.every(q => !!bgQuestionAnswer(q))
  const allMediaPartnerQuestionsAnswered = (electionType: ElectionType) =>
    mediapartnerQuestionsWithValidQuestion(electionType).every(q => !!mediaquestionAnswer(q.question))

  const answeredMunicipalityQuestions = municipalityQuestions.filter(q => !!questionAnswer(q))
  const answeredCountyQuestions = countyQuestions.filter(q => !!questionAnswer(q))

  const answeredBgQuestions = requiredBgQuestions?.filter(q => !!bgQuestionAnswer(q))
  const targetCategory = (id: number) => categories.find(c => c.id === id)

  const allMunicipalityQuestionsAnswered = () => answeredMunicipalityQuestions.length === municipalityQuestions.length
  const allCountyQuestionsAnswered = () => answeredCountyQuestions.length === countyQuestions.length

  const isProfileReadyToPublish = () => {
    let result = true
    if (candidate?.image == null) {
      result = false
    }
    if (matchesMunicipalityElection(candidate) && !allMunicipalityQuestionsAnswered()) {
      result = false
    }
    if (matchesCountyElection(candidate) && !allCountyQuestionsAnswered()) {
      result = false
    }
    return result
  }

  const publishProfile = async () => {
    if (await dialogStore.openDialog(t('summaryPage.publishConfirmTitle'), t('summaryPage.publishConfirmBody'), 'warning')) {
      await answerStore.finalizeAnswering()
      window.scrollTo(0, 0)
    }
  }

  function showCandidateStatusDialog() {
    switch (true) {
      case readOnly:
        return (
          <StatusNote
            className="successNotification"
            notification={{
              id: 0,
              type: 'success',
              hideClose: true,
              message: (
                <>
                  <StatusNoteBody>
                    <StatusNoteTitle>{t('summaryPage.profilePublishedTitle')}</StatusNoteTitle>
                    {t('summaryPage.profilePublished')}
                  </StatusNoteBody>
                </>
              )
            }}
            donePercentage={0}
          />
        )
      case isProfileReadyToPublish():
        return (
          <StatusNote
            className="successNotification"
            notification={{
              id: 0,
              type: 'success',
              hideClose: true,
              message: (
                <>
                  <StatusNoteBody>
                    <StatusNoteTitle>{t('summaryPage.profileReadyForPublishTitle')}</StatusNoteTitle>
                    {t('summaryPage.profileReadyForPublish')}
                  </StatusNoteBody>
                </>
              )
            }}
            donePercentage={0}
          />
        )
      default:
        return (
          <StatusNote
            className="errorNotification"
            notification={{
              id: 0,
              type: 'error',
              hideClose: true,
              message: (
                <>
                  <StatusNoteBody>
                    <StatusNoteTitle>{t('summaryPage.profileNotReadyForPublishTitle')}</StatusNoteTitle>
                    {t('summaryPage.profileNotReadyForPublish')}
                  </StatusNoteBody>
                </>
              )
            }}
            donePercentage={0}
          />
        )
    }
  }

  const municipalityVideoId = candidate?.video
  const countyVideoId = candidate?.election_type === ElectionType.Both ? candidate.extra_video : candidate?.video

  const municipalityMpAnswers = mediapartnerQuestionAnswers.filter(
    a =>
      mediapartnerQuestions.find(q => q.question?.id === a.mediapartner_question_id)?.question?.election_type === ElectionType.Municipality
  )
  const countyMpAnswers = mediapartnerQuestionAnswers.filter(
    a => mediapartnerQuestions.find(q => q.question?.id === a.mediapartner_question_id)?.question?.election_type === ElectionType.County
  )

  return (
    <AnswersWrapper header={t('summaryPage.mainHeader')}>
      {showCandidateStatusDialog()}
      <Stack $paddingTop="gutter" $width="100%" $paddingX="xsmall">
        <Span $fontSize="xl" $fontWeight="bold">
          {t('summaryPage.general')}
        </Span>
        <Stack $space="small" $paddingBottom="xlarge">
          <SummaryCardWide
            title={t('summaryPage.bgQuestions')}
            description={
              allBgQuestionsAnswered()
                ? `${answeredBgQuestions.length}/${requiredBgQuestions.length} ${t('summaryPage.bgQuestionsCompleted')}`
                : `${answeredBgQuestions.length}/${requiredBgQuestions.length} ${t('summaryPage.bgQuestionsAmountAnswered')}`
            }
            onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/taustatiedot/taustakysymykset`)}
            icon={<RiQuestionAnswerLine size={20} />}
            status={allBgQuestionsAnswered() ? 'success' : 'error'}
            maxShown={10}
            rows={bgQuestions.map(bgq => ({
              title: <QuestionTitle>{I18nHelper.getBackgroundQuestionTitle(bgq, i18n.language)}</QuestionTitle>,
              value: bgAnswerIndicator(bgq),
              noRowWrap: true
            }))}
            translations={{
              edit: t('summaryPage.edit'),
              more: t('summaryPage.more')
            }}
          />
          <SummaryCardWide
            title={t('summaryPage.photo')}
            description={candidate?.image ? t('summaryPage.photo') : t('summaryPage.missing')}
            onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/taustatiedot/kuva`)}
            icon={candidate?.image && <CandidateImage src={`${IMG_PREFIX}/${candidate.image}_thumb`} alt="ehdokaskuva" />}
            status={candidate?.image ? 'success' : 'error'}
            rows={[]}
            translations={{
              edit: t('summaryPage.edit'),
              more: t('summaryPage.more')
            }}
          />
        </Stack>

        {matchesCountyElection(candidate) && (
          <Stack $space="small" $paddingBottom="xlarge">
            <Span $fontSize="xl" $fontWeight="bold">
              {t('summaryPage.countyElection')}
            </Span>
            <SummaryCardWide
              title={t('summaryPage.video')}
              description={!!countyVideoId ? t('summaryPage.video') : t('summaryPage.videoMissing')}
              onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/aluevaalit/video`)}
              icon={<RiVideoChatLine />}
              status={candidate?.video ? 'success' : 'error'}
              rows={
                candidate?.video
                  ? [
                      {
                        title: '',
                        value: (
                          <Video>
                            <AreenaVideo videoId={countyVideoId} />
                          </Video>
                        )
                      }
                    ]
                  : []
              }
              translations={{
                edit: t('summaryPage.edit'),
                more: t('summaryPage.more')
              }}
            />
            <SummaryCardWide
              title={t('summaryPage.questions')}
              description={
                allCountyQuestionsAnswered()
                  ? `${answeredCountyQuestions.length}/${countyQuestions.length} ${t('summaryPage.completed')}`
                  : `${answeredCountyQuestions.length}/${countyQuestions.length} ${t('summaryPage.amountAnswered')}`
              }
              onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/aluevaalit/kysymykset`)}
              icon={<RiCompass3Line size={20} />}
              status={allCountyQuestionsAnswered() ? 'success' : 'error'}
              rows={countyQuestions.map((q, index) => ({
                title: <QuestionTitle>{I18nHelper.getTranslatedQuestion(q, i18n)}</QuestionTitle>,
                value: questionAnswer(q) ? (
                  <TextWrapper>{questionAnswer(q)}</TextWrapper>
                ) : (
                  <TextWrapper>
                    <NotAnswered>{t('summaryPage.notAnswered')}</NotAnswered>
                  </TextWrapper>
                ),
                noRowWrap: true,
                sectionHeader:
                  index === 0 ? (
                    <SectionHeader>{I18nHelper.getTranslatedCategory(targetCategory(q.category_id), i18n)}</SectionHeader>
                  ) : undefined
              }))}
              maxShown={10}
              translations={{
                edit: t('summaryPage.edit'),
                more: t('summaryPage.more')
              }}
            />
            {mediapartnerStore.hasCountyQuestions && (
              <SummaryCardWide
                title={t('summaryPage.mediaquestions')}
                description={
                  allMediaPartnerQuestionsAnswered(ElectionType.County)
                    ? `${countyMpAnswers.length}/${mediapartnerQuestionsWithValidQuestion(ElectionType.County).length} ${t('summaryPage.completed')}`
                    : `${countyMpAnswers.filter(a => !!a.answer).length}/${mediapartnerQuestionsWithValidQuestion(ElectionType.County).length} 
          ${t('summaryPage.amountAnswered')}`
                }
                onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/aluevaalit/mediakumppanit`)}
                icon={<RiCompass3Line size={20} />}
                status={allMediaPartnerQuestionsAnswered(ElectionType.County) ? 'success' : 'error'}
                rows={mediapartnerQuestionsWithValidQuestion(ElectionType.County).map((q, index) => ({
                  title: <QuestionTitle>{I18nHelper.getTranslatedQuestion(q.question, i18n)}</QuestionTitle>,
                  value: mediaquestionAnswer(q.question) ? (
                    <TextWrapper>{mediaquestionAnswer(q.question)}</TextWrapper>
                  ) : (
                    <TextWrapper>
                      <NotAnsweredNotRequired>{t('summaryPage.notAnswered')}</NotAnsweredNotRequired>
                    </TextWrapper>
                  ),
                  noRowWrap: true,
                  sectionHeader: <SectionHeader>{I18nHelper.getMediaPartnerName(q.mediapartner, i18n)}</SectionHeader>
                }))}
                maxShown={10}
                translations={{
                  edit: t('summaryPage.edit'),
                  more: t('summaryPage.more')
                }}
              />
            )}
          </Stack>
        )}
        {matchesMunicipalityElection(candidate) && (
          <Stack $space="small" $paddingBottom="xlarge">
            <Span $fontSize="xl" $fontWeight="bold">
              {t('summaryPage.municipalityElection')}
            </Span>
            <SummaryCardWide
              title={t('summaryPage.video')}
              description={!!municipalityVideoId ? t('summaryPage.video') : t('summaryPage.videoMissing')}
              onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/kuntavaalit/video`)}
              icon={<RiVideoChatLine />}
              status={candidate?.video ? 'success' : 'error'}
              rows={
                candidate?.video
                  ? [
                      {
                        title: '',
                        value: (
                          <Video>
                            <AreenaVideo videoId={municipalityVideoId} />
                          </Video>
                        )
                      }
                    ]
                  : []
              }
              translations={{
                edit: t('summaryPage.edit'),
                more: t('summaryPage.more')
              }}
            />
            <SummaryCardWide
              title={t('summaryPage.questions')}
              description={
                allMunicipalityQuestionsAnswered()
                  ? `${answeredMunicipalityQuestions.length}/${municipalityQuestions.length} ${t('summaryPage.completed')}`
                  : `${answeredMunicipalityQuestions.length}/${municipalityQuestions.length} ${t('summaryPage.amountAnswered')}`
              }
              onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/kuntavaalit/kysymykset`)}
              icon={<RiCompass3Line size={20} />}
              status={allMunicipalityQuestionsAnswered() ? 'success' : 'error'}
              rows={municipalityQuestions.map((q, index) => ({
                title: <QuestionTitle>{I18nHelper.getTranslatedQuestion(q, i18n)}</QuestionTitle>,
                value: questionAnswer(q) ? (
                  <TextWrapper>{questionAnswer(q)}</TextWrapper>
                ) : (
                  <TextWrapper>
                    <NotAnswered>{t('summaryPage.notAnswered')}</NotAnswered>
                  </TextWrapper>
                ),
                noRowWrap: true,
                sectionHeader:
                  index === 0 ? (
                    <SectionHeader>{I18nHelper.getTranslatedCategory(targetCategory(q.category_id), i18n)}</SectionHeader>
                  ) : undefined
              }))}
              maxShown={10}
              translations={{
                edit: t('summaryPage.edit'),
                more: t('summaryPage.more')
              }}
            />
            {mediapartnerStore.hasMunicipalityQuestions && (
              <SummaryCardWide
                title={t('summaryPage.mediaquestions')}
                description={
                  allMediaPartnerQuestionsAnswered(ElectionType.Municipality)
                    ? `${municipalityMpAnswers.length}/${mediapartnerQuestionsWithValidQuestion(ElectionType.Municipality).length} ${t('summaryPage.completed')}`
                    : `${municipalityMpAnswers.filter(a => !!a.answer).length}/${mediapartnerQuestionsWithValidQuestion(ElectionType.Municipality).length} 
          ${t('summaryPage.amountAnswered')}`
                }
                onEdit={readOnly ? undefined : () => navigate(`/${candidateId}/kuntavaalit/mediakumppanit`)}
                icon={<RiCompass3Line size={20} />}
                status={allMediaPartnerQuestionsAnswered(ElectionType.Municipality) ? 'success' : 'error'}
                rows={mediapartnerQuestionsWithValidQuestion(ElectionType.Municipality).map((q, index) => ({
                  title: <QuestionTitle>{I18nHelper.getTranslatedQuestion(q.question, i18n)}</QuestionTitle>,
                  value: mediaquestionAnswer(q.question) ? (
                    <TextWrapper>{mediaquestionAnswer(q.question)}</TextWrapper>
                  ) : (
                    <TextWrapper>
                      <NotAnsweredNotRequired>{t('summaryPage.notAnswered')}</NotAnsweredNotRequired>
                    </TextWrapper>
                  ),
                  noRowWrap: true,
                  sectionHeader: <SectionHeader>{I18nHelper.getMediaPartnerName(q.mediapartner, i18n)}</SectionHeader>
                }))}
                maxShown={10}
                translations={{
                  edit: t('summaryPage.edit'),
                  more: t('summaryPage.more')
                }}
              />
            )}
          </Stack>
        )}
        <NextBackNav
          backTo={() =>
            navigate(
              `/${candidateId}/${candidate?.election_type === ElectionType.Both || candidate?.election_type === ElectionType.County ? 'aluevaalit' : 'kuntavaalit'}/mediakumppanit`
            )
          }
          nextTo={() => publishProfile()}
          onSummaryPage
          continueDisabled={!isProfileReadyToPublish() || readOnly}
          translations={{
            next: t('nextBackNav.continue'),
            back: t('nextBackNav.back'),
            finish: t('nextBackNav.publishProfile')
          }}
        />
      </Stack>
    </AnswersWrapper>
  )
})

export const SummaryPage = SummaryPageClass

const Video = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

const CandidateImage = styled.img`
  width: 45px;
  height: 45px;
  border-radius: 100%;
`

const TextWrapper = styled.div<{lineBreak?: boolean}>`
  ${textNormal};
  width: 40%;
  margin-left: ${spacing.space_72}px;
  text-align: left;

  ${({lineBreak}) => lineBreak && 'white-space: pre-wrap;'}

  @media only screen and (max-width: ${breakpoints.mobile}) {
    width: 100%;
    margin-left: 0;
  }
`

const QuestionTitle = styled.p`
  ${textNormal};
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const SectionHeader = styled.p`
  ${textBold};
  display: flex;
  text-align: left;
  position: absolute;
  padding-bottom: 64px;
  font-weight: 600;

  @media only screen and (max-width: ${breakpoints.mobile}) {
    position: relative;
    padding-bottom: 0;
  }
`

const SummaryCardWide = styled(SummaryCard)`
  width: 100%;
  :not(:last-child) {
    margin-bottom: ${spacing.space_24}px;
  }
  button > div {
    margin-bottom: 0 !important; // otherwise it looks dumb on mobile
  }
  p + div {
    overflow-wrap: break-word;
  }
  h1 {
    overflow-wrap: anywhere;
  }
`
const ThemeParagraph = styled.p`
  ${textNormal};
  text-align: left;
`
const NotAnswered = styled(ThemeParagraph)`
  color: ${({theme}) => theme.errorPrimary};
  font-weight: bold;
`
const NotAnsweredNotRequired = styled(ThemeParagraph)`
  color: ${({theme}) => theme.warningPrimary};
`
const StatusNote = styled(Notification)`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  margin-bottom: ${spacing.space_16}px;
  padding: 14px 60px;

  img {
    left: 19px;
  }

  @media only screen and (max-width: ${breakpoints.mobileS}) {
    padding: 14px 44px;

    img {
      left: 10px;
    }
  }
`

const StatusNoteBody = styled.div`
  text-align: left;
`
const StatusNoteTitle = styled.div`
  font-weight: bold;
  margin-bottom: ${spacing.space_8}px;
`
