import {CandidateStore} from './CandidateStore'
import {NotificationStore} from './NotificationStore'
import {QuestionStore} from './QuestionStore'
import {AnswerStore} from './AnswerStore'
import {BackgroundInfoStore} from './BackgroundInfoStore'
import {BackgroundAnswerStore} from './BackgroundAnswerStore'
import {DialogStore} from './DialogStore'
import {MediapartnerStore} from './MediapartnerStore'
import {AppStateStore} from './AppStateStore'
import {RegistrationStore} from './RegistrationStore'

export class Stores {
  public candidateStore: CandidateStore

  public questionStore: QuestionStore

  public notificationStore: NotificationStore

  public answerStore: AnswerStore

  public backgroundInfoStore: BackgroundInfoStore

  public backgroundAnswerStore: BackgroundAnswerStore

  public dialogStore: DialogStore

  public mediapartnerStore: MediapartnerStore

  public appStateStore: AppStateStore

  public registrationStore: RegistrationStore

  constructor() {
    this.notificationStore = new NotificationStore()
    this.candidateStore = new CandidateStore(this.notificationStore)
    this.questionStore = new QuestionStore(this.notificationStore)
    this.answerStore = new AnswerStore(this.notificationStore, this.candidateStore)
    this.backgroundInfoStore = new BackgroundInfoStore(this.notificationStore)
    this.backgroundAnswerStore = new BackgroundAnswerStore(this.notificationStore, this.candidateStore)
    this.dialogStore = new DialogStore()
    this.mediapartnerStore = new MediapartnerStore(this.notificationStore, this.candidateStore)
    this.appStateStore = new AppStateStore()
    this.registrationStore = new RegistrationStore()
  }
}
