import {action, runInAction, computed, makeAutoObservable} from 'mobx'
import {IMediapartner} from '../types/mediapartner'
import {get, post, authenticatedHeaders} from '../api/methods'
import {IMediapartnerQuestion} from '../types/question'
import {IMediapartnerQuestionAnswer} from '../types/answer'
import {NotificationStore} from './NotificationStore'
import {CandidateStore} from './CandidateStore'
import {commonI18N} from '@webscale-oy/vaalikone-common-ui-base'
import { ElectionType } from '../types/election'

export type QuestionWithMediapartner = {
  mediapartner: IMediapartner
  question: IMediapartnerQuestion | undefined
  index: number
}

export class MediapartnerStore {
  mediapartnerQuestions: QuestionWithMediapartner[] = []
  loading = false
  mediapartnerQuestionAnswers: IMediapartnerQuestionAnswer[] = []
  notificationStore: NotificationStore
  candidateStore: CandidateStore

  constructor(notificationsStore: NotificationStore, candidateStore: CandidateStore) {
    this.notificationStore = notificationsStore
    this.candidateStore = candidateStore
    makeAutoObservable(this)
  }

  @action
  async loadMediapartnerQuestions(candidateId: number): Promise<void> {
    this.loading = true
    const result = await get<IMediapartner[]>(`${candidateId}/mediapartners`, authenticatedHeaders())
    runInAction(() => {
      this.mediapartnerQuestions = result.reduce((prev, current) => {
        const {questions, ...mpInfo} = current
        const latestIndex = prev.length > 0 ? prev[prev.length - 1].index : -1
        const mpQuestion1 = {
          mediapartner: mpInfo,
          question: questions && questions.find(q => q.position === 1),
          index: latestIndex + 1
        }
        prev.push(mpQuestion1)
        const mpQuestion2 = {
          mediapartner: mpInfo,
          question: questions && questions.find(q => q.position === 2),
          index: latestIndex + 2
        }
        prev.push(mpQuestion2)

        return prev
      }, [] as QuestionWithMediapartner[])
      this.loading = false
    })
  }

  @action
  async loadMediapartnerQuestionsAnswers(): Promise<void> {
    const candidateId = this.candidateStore.candidate!.id
    this.loading = true
    const result = await get<IMediapartnerQuestionAnswer[]>(`${candidateId}/mediapartner-answer`, authenticatedHeaders())
    runInAction(() => {
      this.mediapartnerQuestionAnswers = result
      this.loading = false
    })
  }

  @action
  async updateMPAnswer(answer: IMediapartnerQuestionAnswer) {
    const {candidateId} = this.candidateStore
    const index = this.mediapartnerQuestionAnswers.findIndex(a => a.mediapartner_question_id === answer.mediapartner_question_id)
    if (index === -1) {
      this.mediapartnerQuestionAnswers.push(answer)
    } else {
      this.mediapartnerQuestionAnswers = this.mediapartnerQuestionAnswers.map(a =>
        a.mediapartner_question_id === answer.mediapartner_question_id ? answer : a
      )
    }
    const result = await post<IMediapartnerQuestionAnswer>(`${candidateId}/mediapartner-answer`, answer, authenticatedHeaders())
    runInAction(() => {
      if (result) {
        this.mediapartnerQuestionAnswers = this.mediapartnerQuestionAnswers.map(a =>
          a.mediapartner_question_id === result.mediapartner_question_id ? result : a
        )
        this.notificationStore.createNotification(commonI18N.t('success.answerSaved'), 'success', 3)
      }
    })
    return !!result
  }

  @computed get mpQuestionCount() {
    return this.mediapartnerQuestions.filter(q => !!q.question).length
  }

  @computed get municipalityQuestions () {
    return this.mediapartnerQuestions.filter(q => q.question?.election_type === ElectionType.Municipality)
  }

  @computed get hasMunicipalityQuestions() {
    return this.municipalityQuestions.length > 0
  }

  @computed get countyQuestions () {
    return this.mediapartnerQuestions.filter(q => q.question?.election_type === ElectionType.County)
  }

  @computed get hasCountyQuestions() {
    return this.countyQuestions.length > 0
  }

  getAnswer(questionId: number): Partial<IMediapartnerQuestionAnswer> | undefined {
    return this.mediapartnerQuestionAnswers.find(c => c.mediapartner_question_id === questionId)
  }
}
