import * as React from 'react'
import styled from 'styled-components'
import {raise, theme} from '../theme'
import {INotification, NotificationStore, NotificationType} from '../stores/NotificationStore'
import alertIcon from '../../images/alert_notification.svg'
import infoIcon from '../../images/info_notification.svg'
import warningIcon from '../../images/warning_notification.svg'
import successIcon from '../../images/check_notification.svg'
import closeIcon from '../../images/close_icon.svg'
import {color} from '@yleisradio/yle-design-system'
import {observer} from 'mobx-react'

type NotificationProps = {
  notification: Pick<INotification, 'id' | 'type' | 'hideClose' | 'message'>
  donePercentage: number
  close?: (id: number) => void
  className?: string
}

const NotificationDialog: React.FC<NotificationProps> = observer(({notification, close, donePercentage, className}) => {
  const getIcon = (type?: NotificationType) => {
    switch (type) {
      case 'warning':
        return <NotificationIcon src={warningIcon} />
      case 'error':
        return <NotificationIcon src={alertIcon} />
      case 'success':
        return <NotificationIcon src={successIcon} />
      default:
        return <NotificationIcon src={infoIcon} />
    }
  }
  return (
    <NotificationWrapper type={notification.type} className={className}>
      {getIcon(notification.type)}
      {notification.message}
      {!notification.hideClose && <CloseIcon src={closeIcon} className="close" onClick={() => close && close(notification.id)} />}

      <TimerLane>
        <TimerProgress data-progress={donePercentage} />
      </TimerLane>
    </NotificationWrapper>
  )
})

type Props = {store: NotificationStore; className?: string}

const NotificationsClass = observer((props: Props) => {
  const close = (id: number) => props.store.removeNotification(id)

  return (
    <div className={props.className}>
      {props.store.notifications.slice(0, 3).map(n => {
        const donePercentage = ((n.duration - n.left) / n.duration) * 100
        return <Notification key={n.id} notification={n} donePercentage={donePercentage} close={close} />
      })}
    </div>
  )
})

export const Notifications = styled(NotificationsClass)`
  position: fixed;
  bottom: 20px;
  left: 20px;
  display: inline-block;
  z-index: 10000;
  @media only screen and (max-width: ${theme.breaks.mobile}px) {
    top: 54px;
    left: 0px;
    bottom: auto;
  }
`

export const Notification = styled(NotificationDialog)``

function getBorderColor(type: NotificationType) {
  switch (type) {
    case 'error':
      return theme.color.error.light
    case 'success':
      return theme.color.success.light
    case 'warning':
      return theme.color.warning.light
    default:
      return theme.color.primary.light
  }
}

function getBackgroundColor(type: NotificationType) {
  switch (type) {
    case 'error':
      return color.RED_20
    case 'success':
      return color.GREEN_20
    case 'warning':
      return color.ORANGE_20
    default:
      return color.BLUE_20
  }
}

const NotificationIcon = styled.img`
  position: absolute;
  top: 15px;
  left: 12px;
`

const CloseIcon = styled.img`
  cursor: pointer;
  position: absolute;
  right: 12px;
  top: 15px;
`

const TimerProgress = styled.div.attrs<{'data-progress': number}>(props => ({
  style: {
    width: `${props['data-progress'] || 0}%`
  }
}))`
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
`
const TimerLane = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
`

const NotificationWrapper = styled.div<{type: NotificationType}>`
  width: 343px;
  height: 80px;
  background-color: rgba(255, 255, 255, 0.9);
  color: ${theme.color.textDark};
  padding: 0 45px 10px 50px;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: ${({type}) => getBackgroundColor(type)};
  color: ${color.GRAY_95};
  border-bottom-width: 0;
  font-family: ${theme.font.paragraph};

  ${TimerLane} {
    background-color: ${({type}) => getBorderColor(type)};
  }
  ${TimerProgress} {
    background-color: ${color.GRAY_95};
  }

  @media only screen and (max-width: ${theme.breaks.mobile}px) {
    padding: 10px 10px 10px 45px;
    min-height: 60px;
    height: auto;
    width: 100vw;
    border-radius: 0px;
    ${CloseIcon} {
      display: none;
    }
  }
  ${raise(2)};
`
