export const faqs = {
  fi: [
    {
      question: 'Millaisen kuvan voin ladata vaalikoneeseen?',
      answer: `Kuvan pienin mahdollinen koko on 100x133 pikseliä. Suositeltu koko on vähintään 360x480 pikseliä.
        Tuetut kuvaformaatit ovat .jpg, .jpeg ja .png`
    },
    {
      question: 'Voinko tallentaa vastaukseni ja tulla myöhemmin takaisin vastaamaan?',
      answer: `Voit, vastauksesi tallentuvat automaattisesti. Voit myöhemmin jatkaa vastaamista kirjautumalla ehdokaskoneeseen uudestaan osoitteessa https://ehdokaskone.yle.fi/tunnistaudu`
    },
    {
      question: 'Voinko muokata vastauksiani tai vaihtaa kuvaani myöhemmin?',
      answer:
        'Kyllä voit. Pääset takaisin vastaamaan vaalikoneeseen kirjautumislinkistä https://ehdokaskone.yle.fi/tunnistaudu. Vastaukset tulee antaa 2. maaliskuuta kello 23.59 mennessä.'
    },
    {
      question: 'Kuinka kauan voin vastata vaalikoneen kysymyksiin?',
      answer: 'Voit käydä vastaamassa kysymyksiin niin pitkään kuin ehdokaskone on auki'
    },
    {
      question: 'Milloin alue- ja kuntavaaleissa voi äänestää?',
      answer: 'Ennakkoäänestys kotimaassa 2.–8.4.2025 ja ulkomailla 2.–5.4.2025. Vaalipäivän äänestys on 13.4.2025.'
    },
    {
      question: 'Voiko muut käydä vastaamassa puolestani?',
      answer: `Ehdokaskoneeseen kirjaudutaan vahvalla tunnistautumisella (pankkitunnistautuminen tai mobiilivarmenne) ja tunnusten antaminen eteenpäin ei ole sallittua.`
    },
    {
      question: 'Miten taustakysymykset vaikuttavat ehdokaskoneeseen?',
      answer: `Vastauksesi taustakysymyksiin näkyvät esittelysivullasi, kun vaalikone on auki.
        Vastauksesi eivät vaikuta ehdokaskoneen suosituksiin sen vastaajille.`
    }
  ],
  sv: [
    {
      question: 'Hurudan profilbild kan jag ladda upp?',
      answer: `Minsta bilden du kan ladda upp är 100x133 pixel. Minsta rekommenderade storleken är 360x480 pixel.
        Stödda filtyper är .jpg, .jpeg och .png.`
    },
    {
      question: 'Kan jag spara mina svar och fortsätta att svara senare?',
      answer: `Det kan du, dina svar sparas automatiskt. Du kan fortsätta att svara senare genom att logga in i kandidatkompassen på nytt. Adressen är https://ehdokaskone.yle.fi/tunnistaudu`
    },
    {
      question: 'Kan jag ändra på mina svar eller byta bild senare?',
      answer: `Jo det kan du. Du kommer tillbaka in i valkompassen via inloggningslänken https://ehdokaskone.yle.fi/tunnistaudu. Dina slutgiltiga svar måste vara inne före den 2 mars klockan 23.59.`
    },
    {
      question: 'Hur lång tid har jag på mig att fylla i valkompassen?',
      answer:
        'Du kan svara på valkompassens frågor så länge som den är öppen för kandidater.'
    },
    {
      question: 'När kan man rösta i Välfärdsområdes- och kommunalvalet?',
      answer: 'Förhandsröstning i Finland den 2–8 april 2025 och utomlands den 2–5 april 2025. Den egentliga valdagen är den 13 april 2025.'
    },
    {
      question: 'Kan någon annan svara för min räkning?',
      answer: `Man loggar in i kandidatkompassengenom att identifiera sig med bankkoder eller mobil-id. Det är inte tillåtet att ge sina bankkoder eller mobil-id till någon annan.`
    },
    {
      question: 'Hur inverkar bakgrundsfrågorna på valkompassen?',
      answer: `Dina svar på bakgrundsfrågorna visas på din profilsida när valkompassen är öppen för publiken.
        Svaren på bakgrundsfrågorna påverkar inte valkompassens rekommendationer.`
    }
  ]
}
